// import React, { useEffect, useState } from "react";
// import Slider from "react-slick";
// import axios from "axios";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import {  Box } from "@mui/material"; 
// import toast from "react-hot-toast";


// const apiUrl = process.env.REACT_APP_SERVER_URL;


// const ProductCarousel = () => {
//   const [products, setProducts] = useState([]);

//   //get all products
//   const getAllProducts = async () => {
//     try {
//       const { data } = await axios.get(`${apiUrl}/product/getProduct`);
//       setProducts(data.products);
//     } catch (error) {
//       console.log(error);
//       toast.error("Something Went Wrong");
//     }
//   };

//   //lifecycle method
//   useEffect(() => {
//     getAllProducts();
//   }, []);

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     autoplay: true, 
//     autoplaySpeed: 3000,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <Box>
      
//       <Slider
//         {...settings}
//         style={{
//           marginTop: "100px",
//           }}
//       >
//         {products.map((product) => (
//           <Box key={product._id} p={3}>
//             <img
//               src={`/product/productPhoto/${product._id}`}
//               alt={product.name}
//               style={{ width: "100%", height: "auto" }}
              
//             />
//             </Box>
//         ))}
//       </Slider>
//     </Box>
//   );
// };

// export default ProductCarousel;
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import toast from "react-hot-toast";

const apiUrl = process.env.REACT_APP_SERVER_URL;

const ProductCarousel = () => {
  const [products, setProducts] = useState([]);

  // Function to get all products
  const getAllProducts = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/product/getProduct`);
      setProducts(data.products);
    } catch (error) {
      console.error(error);
      toast.error("Something Went Wrong");
    }
  };

  // Lifecycle method to fetch products when the component mounts
  useEffect(() => {
    getAllProducts();
  }, []);

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ width: "100%", height: "auto", mt: 5 }}>
      <Slider {...settings}>
        {products.map((product) => (
          <Box key={product._id} p={2}>
            <img
              src={`${apiUrl}/product/productPhoto/${product._id}`}
              alt={product.name}
              style={{
                width: "100%", // Fill the container width
                height: "400px", // Fixed height for all images
                objectFit: "cover", // Ensure image covers the container without distortion
              }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ProductCarousel;
