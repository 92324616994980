import React, { useState } from "react";
import Layout from "../../../components/layout/Layout";
import AddExpenses from "./AddExpenses";
import ExpensesTable from "./ExpensesTable";
import { Box } from "@mui/material";
import PasswordPrompt from "../../../components/Charts/PasswordPrompt";

const Expenses = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePasswordCorrect = () => {
    setIsAuthenticated(true);
  };

  return (
    <Layout>
      {isAuthenticated ? (
        <>
          <Box
            sx={{
              margin: "20px",
              direction: "rtl",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AddExpenses />
          </Box>
          <Box
            sx={{
              margin: "20px",
              direction: "rtl",
            }}
          >
            <ExpensesTable />
          </Box>
        </>
      ) : (
        <PasswordPrompt onPasswordCorrect={handlePasswordCorrect} />
      )}
    </Layout>
  );
};

export default Expenses;
