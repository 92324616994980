import { Box, Container, Typography, Link } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 4,
      }}
      component="footer"
    >
      <Container maxWidth="sm">
        <Typography variant="body2" color="text.secondary" align="center">
          {"Copyright © "}
          <Link
            color="primary"
            variant="body1"
            href="https://www.instagram.com/silver__lavaa"
            underline="none"
          >
            Silver Lavaa
          </Link>{" "}
          2024
          {"."}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
