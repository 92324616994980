import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import toast from "react-hot-toast";
import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/image/background2.png";

const apiUrl = process.env.REACT_APP_SERVER_URL;

const CreateProduct = () => {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [wight, setWight] = useState("");
  const [category, setCategory] = useState("");
  const [photo, setPhoto] = useState("");
  
  const navigate = useNavigate();

  // const handleFileChange = (e) => {
  //   setPhoto(e.target.files[0]);
  // };
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 800; // Maximum width of the image
          const MAX_HEIGHT = 800; // Maximum height of the image
          let width = img.width;
          let height = img.height;

          // Calculate new dimensions while maintaining the aspect ratio
          if (width > height) {
            if (width > MAX_WIDTH) {
              height = Math.round((height *= MAX_WIDTH / width));
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = Math.round((width *= MAX_HEIGHT / height));
              height = MAX_HEIGHT;
            }
          }

          // Set canvas dimensions to new calculated size
          canvas.width = width;
          canvas.height = height;

          // Draw the image on canvas and compress it to JPEG format
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob(
            (blob) => {
              if (blob.size > 1000000) {
                toast.error("Unable to compress image below 1MB");
              } else {
                const compressedFile = new File([blob], selectedFile.name, {
                  type: "image/jpeg",
                  lastModified: Date.now(),
                });
                setPhoto(compressedFile);
              }
            },
            "image/jpeg",
            0.7 // Compression quality (0 to 1, where 1 is highest quality)
          );
        };
      };

      reader.readAsDataURL(selectedFile);
    }
  };


  //get all Categories
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/category/getCategory`);
      if (data?.success) {
        setCategories(data?.categories);
      } else {
        console.error(
          "API response does not contain the expected 'categories' property:",
          data
        );
        toast.error("API response format is incorrect");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong in getting category");
    }
  };
  useEffect(() => {
    getAllCategory();
  }, []);

  //create product function
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      const productData = new FormData();
      productData.append("name", name);
      productData.append("itemCode", itemCode);
      productData.append("price", price);
      productData.append("quantity", quantity);
      productData.append("wight", wight);
      productData.append("photo", photo);
      productData.append("category", category);
      const { data } = await axios.post(
        `${apiUrl}/product/createProduct`,
        productData
      );
      if (data?.success) {
        toast.success("Product Created Successfully");
        navigate("/product");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "600px",
            padding: "20px",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
            boxShadow: "10px 0px 10px rgba(255, 255, 255, 0.3)",
          }}
        >
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            اضافة منتج{/* Create Product */}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "600px",
                padding: "20px",
              }}
            >
              <FormControl>
                <Select
                  labelId="category-label"
                  value={category}
                  onChange={(event) => setCategory(event.target.value)}
                  displayEmpty
                  sx={{ width: "500px" }}
                >
                  <MenuItem disabled value="">
                    {/* <em>Select a category</em> */}
                    <em>اختر فئة</em>
                  </MenuItem>
                  {categories?.map((c) => (
                    <MenuItem key={c._id} value={c._id}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12} md={6} sx={{ margin: "30px 0 10px 100px" }}>
                  <input
                    type="file"
                    accept="image/*"
                    id="photo-input"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="photo-input">
                    <Button variant="outlined" component="span" fullWidth>
                      {photo ? photo.name : "Upload Photo"}
                    </Button>
                  </label>
                </Grid>
                {photo && (
                  <Grid item xs={12} md={6}>
                    <div className="text-center">
                      <img
                        src={URL.createObjectURL(photo)}
                        alt="product_photo"
                        height={200}
                        className="img img-responsive"
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
              <Box sx={{ margin: "10px" }}>
                <TextField
                  type="text"
                  name="name"
                  value={name}
                  placeholder="الاسم" //"Write a name"
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  autoComplete="name"
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#91C7B1",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#91C7B1",
                    },

                    width: "100%",
                    marginBottom: "10px",
                  }}
                />

                <TextField
                  type="text"
                  name="itemCode"
                  value={itemCode}
                  placeholder="رمز المنتج" //"Write a itemCode"
                  onChange={(e) => setItemCode(e.target.value)}
                  fullWidth
                  variant="outlined"
                  autoComplete="itemCode"
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#91C7B1",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#91C7B1",
                    },

                    width: "100%",
                    marginBottom: "10px",
                  }}
                />
               
                <TextField
                  type="text"
                  name="price"
                  value={price}
                  placeholder="السعر" //"Write a price"
                  onChange={(e) => setPrice(e.target.value)}
                  fullWidth
                  variant="outlined"
                  autoComplete="price"
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#91C7B1",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#91C7B1",
                    },

                    width: "100%",
                    marginBottom: "10px",
                  }}
                />

                <TextField
                  type="text"
                  name="quantity"
                  value={quantity}
                  placeholder="العدد" //"Write a quantity"
                  onChange={(e) => setQuantity(e.target.value)}
                  fullWidth
                  variant="outlined"
                  autoComplete="quantity"
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#91C7B1",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#91C7B1",
                    },

                    width: "100%",
                    marginBottom: "10px",
                  }}
                />

                <TextField
                  type="text"
                  name="wight"
                  value={wight}
                  placeholder="الوزن" //"Write a wight"
                  onChange={(e) => setWight(e.target.value)}
                  fullWidth
                  variant="outlined"
                  autoComplete="wight"
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#91C7B1",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#91C7B1",
                    },

                    width: "100%",
                    marginBottom: "10px",
                  }}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontSize: "25px",
                  bgcolor: "#44484e",
                  "&:hover": {
                    bgcolor: "#80868e",
                  },
                  width: "50%",
                }}
                onClick={handleCreate}
              >
                اضافة منتج{/* Create Product */}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default CreateProduct;

