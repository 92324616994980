import React, { useState } from "react";
import Layout from "../../../components/layout/Layout";
import { Box, Grid } from "@mui/material";
import AddDebt from "../debt/AddDebt";
import AddPayment from "../payment/AddPayment";
import DebtTable from "../debt/DebtTable";
import PaymentTable from "../payment/PaymentTable";
import CalculateTable from "./CalculateTable";
import PasswordPrompt from "../../../components/Charts/PasswordPrompt";

const DebtPayment = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePasswordCorrect = () => {
    setIsAuthenticated(true);
  };

  return (
    <Layout>
      {isAuthenticated ? (
        <>
          {/* <div>DebtPayment</div> */}
          <Box sx={{ width: "100%", marginTop: "20px" }}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AddPayment />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AddDebt />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ width: "100%", margin: "20px", direction: "rtl" }}>
            <DebtTable />
            <PaymentTable />
            <CalculateTable />
          </Box>
        </>
      ) : (
        <PasswordPrompt onPasswordCorrect={handlePasswordCorrect} />
      )}
    </Layout>
  );
};

export default DebtPayment;
