import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import CustomerChart from "../components/Charts/CustomerChart";
// import ProductChart from "../components/Charts/ProductChart";
// import ProductPriceChart from "../components/Charts/ProductPriceChart";
import PasswordPrompt from "../components/Charts/PasswordPrompt";

const Charts = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handlePasswordCorrect = () => {
    setIsAuthenticated(true);
  };

  return (
    <Layout>
      {isAuthenticated ? (
        <>
          <CustomerChart />
          {/* <ProductChart /> */}
          {/* <ProductPriceChart /> */}
        </>
      ) : (
        <PasswordPrompt onPasswordCorrect={handlePasswordCorrect} />
      )}
    </Layout>
  );
};

export default Charts;
