import React from "react";
import Layout from "../../components/layout/Layout";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import logo from "../../assets/image/logoBlack.png";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import backgroundImage from "../../assets/image/background2.png";
import { toJpeg } from "html-to-image"; // Corrected import
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
// import html2canvas from "html2canvas";

const apiUrl = process.env.REACT_APP_SERVER_URL;

const AllCustomer = () => {
  const [customers, setCustomers] = useState([]);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");

  const getAllCustomers = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/customer/getCustomer`);

      setCustomers(data.customers);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };

  useEffect(() => {
    getAllCustomers();
  }, []);

  const formatDate = (date) => {
    return dayjs(date).format("MM/DD/YYYY");
  };

  

  const saveAsImage = async (customerName, customerID) => {
    const element = document.getElementById(`customer-${customerID}`); 
    // console.log("Element with id='customerID':", element);

    if (!element) {
      console.error("Element not found");
      return;
    }

    try {
      const dataUrl = await toJpeg(element);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = `${customerName}.jpg`;
      link.click();
    } catch (error) {
      console.error("Error saving image:", error);
    }
  };
  



// const saveAsImage = async (customerName, customerID) => {
//   const element = document.getElementById(`customer-${customerID}`);

//   if (!element) {
//     console.error("Element not found");
//     return;
//   }

//   try {
//     const canvas = await html2canvas(element, { useCORS: true });
//     const dataUrl = canvas.toDataURL("image/jpeg");
//     const link = document.createElement("a");
//     link.href = dataUrl;
//     link.download = `${customerName}.jpg`;
//     link.click();
//   } catch (error) {
//     console.error("Error saving image:", error);
//   }
// };

  const handleDelete = (productId) => {
    setDeleteId(productId);
    setConfirmDelete(true);
  };

  // Confirm delete action
  const confirmDeleteAction = async () => {
    try {
      await axios.delete(`${apiUrl}/customer/deleteCustomer/${deleteId}`);
      setCustomers(customers.filter((customer) => customer._id !== deleteId));
      toast.success("customer Deleted Successfully");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
    setConfirmDelete(false);
    setDeleteId(null);
  };

  // Filter customers based on search query
  const filteredCustomers = customers.filter((customer) => {
    return customer.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <Layout>
      <Box
        sx={{ textAlign: "center", marginTop: "10px", marginBottom: "30px" }}
      >
        <TextField
          variant="outlined"
          placeholder="بحث ..."
          // label="Search by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          fullWidth
          sx={{
            backgroundColor: "white",
            color: "black",
            width: "60%",
            marginTop: "25px",
            marginLeft: "50px",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#91C7B1",
              },
            },
          }}
          dir="rtl"
        />
      </Box>

      <Grid container spacing={2} justifyContent="flex-end">
        {/* {customers.map((cust) => ( */}
        {filteredCustomers.map((cust) => (
          <Grid
            container
            sx={{
              display: "flex",
              margin: 1,
              padding: 1,
              width: 505,
              height: "380px",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
            key={cust._id}
          >
            <Card
              key={cust._id}
              xs={12}
              sm={6}
              md={4}
              sx={{
                display: "flex",
                margin: 0,
                padding: 0,
                width: 500,
                height: "320px",
                flexDirection: "row-reverse",
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundColor: "white",
              }}
              // id="customerID"
              id={`customer-${cust._id}`}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                {/* date */}
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                >
                  <Grid item xs={8}>
                    <TextField
                      variant="standard"
                      value={formatDate(cust.date)}
                      sx={{
                        left: "120px",
                        top: "6px",
                        width: 160,
                        "& input": {
                          fontSize: "21px",
                          fontWeight: "bold",
                          color: "#48484a",
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </Grid>
                </Grid>
                <CardContent sx={{ flex: "1 0 auto", marginTop: "15px" }}>
                  {/* name */}
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    sx={{ marginBottom: "10px" }}
                  >
                    <Grid item xs={8}>
                      <TextField
                        variant="standard"
                        value={cust.name}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{
                          right: "2px",
                          width: 170,
                          top: "-5px",
                        }}
                        dir="rtl"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        style={{ margin: "5px 0 0 38px", fontWeight: "bold" }}
                      >
                        :الاسم
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* itemCode */}
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    sx={{ marginBottom: "10px" }}
                  >
                    <Grid item xs={8}>
                      <TextField
                        variant="standard"
                        value={"#" + cust.product?.itemCode}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{
                          right: "2px",
                          width: 170,
                          top: "-3px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        style={{
                          margin: "10px 0 0 5.5px",
                          fontWeight: "bold",
                        }}
                      >
                        :كود المنتج
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* Quantity */}
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    sx={{ marginBottom: "10px" }}
                  >
                    <Grid item xs={8}>
                      <TextField
                        variant="standard"
                        value={cust.quantity}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{
                          right: "4px",
                          width: 170,
                          top: "-3px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        style={{
                          margin: "10px 0 0 40px",
                          fontWeight: "bold",
                        }}
                      >
                        :العدد
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* Prices */}
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    sx={{ marginBottom: "10px" }}
                  >
                    <Grid item xs={8}>
                      <TextField
                        variant="standard"
                        value={`${cust.price.toLocaleString("en-IQ", {
                          style: "currency",
                          currency: "IQD",
                        })},000`}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{
                          right: "3px",
                          width: 170,
                          top: "-3px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        style={{
                          margin: "10px 0 0 35px",
                          fontWeight: "bold",
                        }}
                      >
                        :السعر
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* wight */}
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    sx={{ marginBottom: "10px" }}
                  >
                    <Grid item xs={8}>
                      <TextField
                        variant="standard"
                        value={`${cust.wight} G`} //{cust.wight}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{
                          right: "5px",
                          width: 170,
                          top: "-3px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="div"
                        style={{
                          margin: "10px 0 0 35px",
                          fontWeight: "bold",
                        }}
                      >
                        :الوزن
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Box>
              {/* photo */}
              <Grid
                container
                spacing={0}
                sx={{ position: "relative", width: 250, height: "300px" }}
              >
                <Grid item xs={8}>
                  <CardMedia
                    component="img"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 200,
                      height: 200,
                    }}
                    image={`${apiUrl}/product/productPhoto/${cust.product?._id}`}
                    alt="Live from space album cover"
                  />
                </Grid>
                <Grid item xs={6}>
                  <CardMedia
                    component="img"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 2,
                      width: 60,
                      height: "auto",
                      margin: "10px",
                    }}
                    image={logo}
                    alt="Logo"
                  />
                </Grid>
                {/* sing */}
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        position: "absolute",
                        top: "120%",
                        left: "40%",
                        transform: "translate(-50%, -50%)",
                        width: 130,
                        height: 200,
                        fontWeight: "bold",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      sx={{
                        position: "absolute",
                        top: "125%",
                        left: "115%",
                        transform: "translate(-50%, -50%)",
                        width: 200,
                        height: 200,
                        fontWeight: "bold",
                      }}
                    >
                      :التوقيع
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
            {/* actions */}
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              sx={{ marginBottom: "10px" }}
            >
              <Grid item>
                <IconButton
                  onClick={() => saveAsImage(cust.name, cust._id)}
                  title="حفظ"
                  sx={{ marginLeft: "10px" }}
                >
                  <SaveAltIcon sx={{ width: "30px", height: "30px" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Link to={`/updateCustomer/${cust._id}`}>
                  <IconButton title="تعديل">
                    <NoteAltIcon sx={{ width: "30px", height: "30px" }} />
                  </IconButton>
                </Link>
              </Grid>
              <Grid item>
                <IconButton title="حذف" onClick={() => handleDelete(cust._id)}>
                  <DeleteIcon sx={{ width: "30px", height: "30px" }} />
                </IconButton>
              </Grid>
              <Grid item xs={2} sx={{ marginLeft: "100px" }}>
                <TextField
                  variant="standard"
                  // value={cust.discount + ",000"}
                  value={
                    cust.discount
                      ? `${cust.discount.toLocaleString("en-IQ", {
                          style: "currency",
                          currency: "IQD",
                        })},000`
                      : "0"
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    left: "35px",
                    width: 80,
                    top: "-3px",
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  gutterBottom
                  variant="body1"
                  component="div"
                  style={{
                    margin: "10px 0 0 40px",
                    fontWeight: "bold",
                  }}
                >
                  :خصم
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this customer?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setConfirmDelete(false)} color="#44484e">
            <CloseIcon />
          </IconButton>
          <IconButton onClick={confirmDeleteAction} color="#44484e" autoFocus>
            <DeleteIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default AllCustomer;

