import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Modal } from "@mui/base/Modal";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import toast from "react-hot-toast";
import axios from "axios";
import CategoryForm from "../../components/Form/CategoryForm";
import CloseIcon from "@mui/icons-material/Close";
import backgroundImage from "../../assets/image/background2.png";

const apiUrl = process.env.REACT_APP_SERVER_URL;

const CreateCategory = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [categories, setCategories] = useState([]);

  const [name, setName] = useState("");
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [updatedName, setUpdatedName] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  
  //handle Form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(`${apiUrl}/category/createCategory`, {
        name,
      });
      if (data?.success) {
        toast.success(`${name} is created`);
        getAllCategory();
        setName("");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong in input form");
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  //get all cat
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/category/getCategory`);

      if (data?.success) {
        setCategories(data?.categories);
      } else {
        console.error(
          "API response does not contain the expected 'categories' property:",
          data
        );
        toast.error("API response format is incorrect");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong in getting category");
    }
  };

  //update category
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put(
        `${apiUrl}/category/updateCategory/${selected._id}`,
        { name: updatedName }
      );
      if (data.success) {
        toast.success(`${updatedName} is updated`);
        setSelected(null);
        setUpdatedName("");
        setVisible(false);
        getAllCategory();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  //delete category
  const handleDelete = async (pId) => {
    try {
      const { data } = await axios.delete(
        `${updatedName}/category/deleteCategory/${pId}`
      );
      if (data.success) {
        toast.success(`category is deleted`);

        getAllCategory();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "500px",
          width: "90%",
          padding: "20px",
          margin: "10px auto",
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
        }}
      >
        <CategoryForm
          handleSubmit={handleSubmit}
          value={name}
          setValue={setName}
        />
      </Box>
      <Paper
        sx={{
          width: "50%",
          display: "flex",
          justifyContent: "center",
          margin: "20px auto",
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          sx={{
            margin: "0 auto",
          }}
        >
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ tableLayout: "auto" }}
              id="CategoryTable"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: { xs: "18px", sm: "25px" },
                      textAlign: "center",
                    }}
                  >
                    التعديل والحذف {/* Actions */}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "18px", sm: "25px" },
                      textAlign: "left",
                      paddingLeft: "15%",
                    }}
                  >
                    الاسم {/* Name */}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {categories &&
                  categories
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    ?.map((category) => (
                      <TableRow key={category._id}>
                        <TableCell
                          sx={{
                            fontSize: { xs: "14px", sm: "18px" },
                            textAlign: "center",
                          }}
                        >
                          <IconButton
                            onClick={() => handleDelete(category._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setVisible(true);
                              setUpdatedName(category.name);
                              setSelected(category);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: { xs: "14px", sm: "18px" },
                            textAlign: "center",
                            paddingRight: "20%",
                          }}
                        >
                          {category.name}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          {categories && (
            <TablePagination
              id="tablePaginationComponent"
              name="rowsPerPage"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={categories.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Grid>
      </Paper>

      <Modal
        open={visible}
        onClose={() => setVisible(false)}
        footer={null}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
        }}
        sx={{
          zIndex: 9999,
        }}
      >
        <Paper
          style={{
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            width: "40%",
            height: "30%",
            overflow: "auto",
            position: "relative",
          }}
        >
          <IconButton
            style={{ position: "absolute", top: "10px", right: "10px" }}
            onClick={() => setVisible(false)}
          >
            <CloseIcon />
          </IconButton>
          <CategoryForm
            value={updatedName}
            setValue={setUpdatedName}
            handleSubmit={handleUpdate}
          />
        </Paper>
      </Modal>
    </Layout>
  );
};

export default CreateCategory;
