import React, { useState } from "react";
import {
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import  axios  from 'axios';
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_SERVER_URL;

const AddPayment = () => {
  const [paymentData, setPaymentData] = useState({
    name: "",
    amount: "",
    date: dayjs().format("YYYY-MM-DD"),
    description: "",
  });
  const [error, setError] = useState("");

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({ ...paymentData, [name]: value });
  };

  const handleAddPayment = async () => {
    // Validate inputs
    if (!paymentData.name || !paymentData.amount || !paymentData.date) {
      setError("All fields are required.");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/payment/createPayment`,
        paymentData
      ); // Adjust endpoint if needed
      console.log("Payment added:", response.data);
      setError(""); // Clear error on success
      // Optionally reset the form
      setPaymentData({ name: "", amount: "", date: "", description: "" });
       window.location.reload();
    } catch (err) {
      setError(err.response?.data?.message || "Failed to add payment.");
    }
  };

  return (
    <Paper sx={{ padding: "18px", textAlign: "center", width: "70%" }}>
      <Typography variant="h5" gutterBottom fontWeight={"bold"}>
        اضافة تسديد
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <FormControl fullWidth margin="normal">
        <TextField
          label="الاسم"
          name="name"
          value={paymentData.name}
          onChange={handleInputChange}
          variant="standard"
          InputProps={{
            style: { textAlign: "right", direction: "rtl" },
          }}
          InputLabelProps={{ style: { right: 30, left: "auto" } }}
          sx={{
            "& .MuiInput-underline": {
              "&:before": {
                borderBottomColor: "#44484e",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#44484e",
              },
              "&:after": {
                borderBottomColor: "#44484e",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#44484e",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#44484e",
            },
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          label="المبلغ"
          name="amount"
          value={paymentData.amount}
          onChange={handleInputChange}
          variant="standard"
          InputProps={{
            style: { textAlign: "right", direction: "rtl" },
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          InputLabelProps={{ style: { right: 30, left: "auto" } }}
          sx={{
            "& .MuiInput-underline": {
              "&:before": {
                borderBottomColor: "#44484e",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#44484e",
              },
              "&:after": {
                borderBottomColor: "#44484e",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#44484e",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#44484e",
            },
          }}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          type="date"
          name="date"
          value={paymentData.date}
          onChange={handleInputChange}
          variant="standard"
          InputProps={{ style: { textAlign: "right" } }}
          InputLabelProps={{
            style: { right: 30, left: "auto" },
            shrink: true,
          }}
          sx={{
            "& .MuiInput-underline": {
              "&:before": {
                borderBottomColor: "#44484e",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#44484e",
              },
              "&:after": {
                borderBottomColor: "#44484e",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#44484e",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#44484e",
            },
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          label="ملاحظات"
          name="description"
          value={paymentData.description}
          onChange={handleInputChange}
          variant="standard"
          InputProps={{
            style: { textAlign: "right", direction: "rtl" },
            
          }}
          InputLabelProps={{ style: { right: 30, left: "auto" } }}
          sx={{
            "& .MuiInput-underline": {
              "&:before": {
                borderBottomColor: "#44484e",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#44484e",
              },
              "&:after": {
                borderBottomColor: "#44484e",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#44484e",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#44484e",
            },
          }}
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddPayment}
        sx={{
          background: "#44484e",
          fontWeight: "bold",
          fontSize: "15px",
          margin: "20px 0 10px 0",
          "&:hover": {
            backgroundColor: "#5c6169",
          },
        }}
      >
        اضافة تسديد
      </Button>
    </Paper>
  );
};

export default AddPayment;
