// the code without TablePagination 

// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Container,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextField,
//   Typography,
// } from "@mui/material";
// import ClearIcon from "@mui/icons-material/Clear";
// import EditIcon from "@mui/icons-material/Edit";
// import axios from "axios";
// import dayjs from "dayjs";

// const apiUrl = process.env.REACT_APP_SERVER_URL;
// const PaymentTable = () => {
//   const [payment, setPayment] = useState([]);
//   const [editDialogOpen, setEditDialogOpen] = useState(false);
//   const [editPayment, setEditPayment] = useState({
//     _id: "",
//     name: "",
//     amount: "",
//     date: "",
//     description: "",
//   });

//   useEffect(() => {
//     fetchPayments();
//   }, []);

//   const fetchPayments = () => {
//     axios
//       .get(`${apiUrl}/payment/getPayment`)
//       .then((response) => {
//         setPayment(response.data || []); // Fallback to an empty array
//       })
//       .catch((error) => {
//         console.error(
//           "There was an error fetching the payment details!",
//           error
//         );
//       });
//   };

//   const deletePayment = (id) => {
//     if (window.confirm("هل أنت متأكد أنك تريد حذف هذا العنصر؟")) {
//       axios
//         .delete(`${apiUrl}/payment/deletePayment/${id}`)
//         .then(() => {
//           // Remove the deleted payment from the state
//           setPayment((prevPayments) =>
//             prevPayments.filter((p) => p._id !== id)
//           );
//           alert("تم حذف العنصر بنجاح");
//         })
//         .catch((error) => {
//           console.error("حدث خطأ أثناء حذف العنصر!", error);
//           alert("فشل في الحذف. حاول مرة أخرى.");
//         });
//     }
//   };

//   const formatDate = (date) => {
//     return dayjs(date).format("YYYY-MM-DD");
//   };

//   const formatAmount = (amount) => {
//     const parsedAmount = parseFloat(amount);
//     return isNaN(parsedAmount)
//       ? "0.00"
//       : parsedAmount.toLocaleString("en-US", {
//           minimumFractionDigits: 3,
//           maximumFractionDigits: 3,
//         });
//   };

//   // Function to handle editing an item
//   const handleEditItem = (payment) => {
//     setEditPayment({
//       _id: payment._id,
//       name: payment.name,
//       amount: payment.amount,
//       date: formatDate(payment.date),
//       description: payment.description,
//     });
//     setEditDialogOpen(true);
//   };

//   // Function to handle saving the edited payment
//   const handleSaveEdit = () => {
//     const { _id, name, amount, date, description } = editPayment;

//     axios
//       .put(`${apiUrl}/payment/updatePayment/${_id}`, {
//         name,
//         amount,
//         date,
//         description,
//       })
//       .then(() => {
//         setPayment((prevPayments) =>
//           prevPayments.map((p) =>
//             p._id === _id
//               ? {
//                   ...p,
//                   name,
//                   amount,
//                   date: dayjs(date).toISOString(),
//                   description,
//                 }
//               : p
//           )
//         );
//         setEditDialogOpen(false);
//         alert("تم التعديل بنجاح");
//         window.location.reload();
//       })
//       .catch((error) => {
//         console.error("حدث خطأ أثناء تعديل البيانات!", error);
//         alert("فشل في التعديل. حاول مرة أخرى.");
//       });
//   };

//   // Update the state when form inputs change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setEditPayment((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   return (
//     <Container>
//       <Typography
//         variant="h5"
//         gutterBottom
//         fontWeight={"bold"}
//         // marginRight={5}
//         marginTop={3}
//         marginBottom={1.5}
//         backgroundColor={"white"}
//         color={"black"}
//         textAlign={"center"}
//       >
//         التسديد
//       </Typography>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>الاسم </TableCell>
//               <TableCell>المبلغ </TableCell>
//               <TableCell>التاريخ </TableCell>
//               <TableCell>ملاحظات </TableCell>
//               <TableCell>{/*Actions*/}</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {payment.length > 0 ? (
//               payment.map((p) => (
//                 <TableRow key={p._id}>
//                   <TableCell>{p.name}</TableCell>
//                   <TableCell>{formatAmount(p.amount)}</TableCell>
//                   <TableCell>{formatDate(p.date)}</TableCell>
//                   <TableCell>{p.description}</TableCell>
//                   <TableCell>
//                     <IconButton
//                       variant="contained"
//                       color="primary"
//                       sx={{
//                         padding: "5px",
//                         margin: "5px",
//                         color: "#44484e",
//                       }}
//                       onClick={() => deletePayment(p._id)}
//                     >
//                       <ClearIcon />
//                     </IconButton>

//                     <IconButton
//                       variant="contained"
//                       color="primary"
//                       sx={{
//                         padding: "5px",
//                         margin: "5px",
//                         color: "#44484e",
//                       }}
//                       onClick={() => handleEditItem(p)}
//                     >
//                       <EditIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={5} align="center">
//                   لا توجد بيانات متاحة
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {/* edit dialog */}
//       <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
//         <DialogTitle
//           sx={{ textAlign: "center", fontWeight: "bold", fontSize: "30px" }}
//         >
//           تعديل
//         </DialogTitle>
//         <DialogContent>
//           <Box sx={{ display: "flex", flexDirection: "column", margin: "5px" }}>
//             <TextField
//               label="الاسم"
//               name="name"
//               value={editPayment.name}
//               onChange={handleInputChange}
//               fullWidth
//               sx={{
//                 marginBottom: "20px",
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#44484e",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   color: "#44484e",
//                 },
//                 "& .MuiInputLabel-root.Mui-focused": {
//                   color: "#44484e",
//                 },
//               }}
//             />
//             <TextField
//               label="المبلغ"
//               name="amount"
//               value={editPayment.amount}
//               onChange={handleInputChange}
//               fullWidth
//               sx={{
//                 marginBottom: "20px",
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#44484e",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   color: "#44484e",
//                 },
//                 "& .MuiInputLabel-root.Mui-focused": {
//                   color: "#44484e",
//                 },
//               }}
//             />
//             <TextField
//               label="التاريخ"
//               type="date"
//               name="date"
//               value={editPayment.date}
//               onChange={handleInputChange}
//               fullWidth
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               sx={{
//                 marginBottom: "20px",
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#44484e",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   color: "#44484e",
//                 },
//                 "& .MuiInputLabel-root.Mui-focused": {
//                   color: "#44484e",
//                 },
//               }}
//             />
//             <TextField
//               label="ملاحظات"
//               name="description"
//               value={editPayment.description}
//               onChange={handleInputChange}
//               fullWidth
//               sx={{
//                 marginBottom: "20px",
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#44484e",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   color: "#44484e",
//                 },
//                 "& .MuiInputLabel-root.Mui-focused": {
//                   color: "#44484e",
//                 },
//               }}
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => setEditDialogOpen(false)}
//             sx={{
//               backgroundColor: "#f6f6f6",
//               color: "#44484e",
//               fontWeight: "bold",
//               "&:hover": {
//                 backgroundColor: "#f6f6f6",
//                 color: "#44484e",
//               },
//             }}
//           >
//             إغلاق
//           </Button>
//           <Button
//             onClick={handleSaveEdit}
//             sx={{
//               backgroundColor: "#44484e",
//               color: "white",
//               fontWeight: "bold",
//               "&:hover": {
//                 backgroundColor: "#44484e",
//                 color: "white",
//               },
//             }}
//           >
//             حفظ
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// };

// export default PaymentTable;

// the code with TablePagination
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination, // Import TablePagination
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_SERVER_URL;

const PaymentTable = () => {
  const [payment, setPayment] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editPayment, setEditPayment] = useState({
    _id: "",
    name: "",
    amount: "",
    date: "",
    description: "",
  });

  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = () => {
    axios
      .get(`${apiUrl}/payment/getPayment`)
      .then((response) => {
        setPayment(response.data || []); // Fallback to an empty array
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the payment details!",
          error
        );
      });
  };

  const deletePayment = (id) => {
    if (window.confirm("هل أنت متأكد أنك تريد حذف هذا العنصر؟")) {
      axios
        .delete(`${apiUrl}/payment/deletePayment/${id}`)
        .then(() => {
          setPayment((prevPayments) =>
            prevPayments.filter((p) => p._id !== id)
          );
          alert("تم حذف العنصر بنجاح");
        })
        .catch((error) => {
          console.error("حدث خطأ أثناء حذف العنصر!", error);
          alert("فشل في الحذف. حاول مرة أخرى.");
        });
    }
  };

  const formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  const formatAmount = (amount) => {
    const parsedAmount = parseFloat(amount);
    return isNaN(parsedAmount)
      ? "0.00"
      : parsedAmount.toLocaleString("en-US", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });
  };

  const handleEditItem = (payment) => {
    setEditPayment({
      _id: payment._id,
      name: payment.name,
      amount: payment.amount,
      date: formatDate(payment.date),
      description: payment.description,
    });
    setEditDialogOpen(true);
  };

  const handleSaveEdit = () => {
    const { _id, name, amount, date, description } = editPayment;

    axios
      .put(`${apiUrl}/payment/updatePayment/${_id}`, {
        name,
        amount,
        date,
        description,
      })
      .then(() => {
        setPayment((prevPayments) =>
          prevPayments.map((p) =>
            p._id === _id
              ? {
                  ...p,
                  name,
                  amount,
                  date: dayjs(date).toISOString(),
                  description,
                }
              : p
          )
        );
        setEditDialogOpen(false);
        alert("تم التعديل بنجاح");
        window.location.reload();
      })
      .catch((error) => {
        console.error("حدث خطأ أثناء تعديل البيانات!", error);
        alert("فشل في التعديل. حاول مرة أخرى.");
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditPayment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle pagination change for rows per page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page is changed
  };

  return (
    <Container>
      <Typography
        variant="h5"
        gutterBottom
        fontWeight={"bold"}
        marginTop={3}
        marginBottom={1.5}
        backgroundColor={"white"}
        color={"black"}
        textAlign={"center"}
      >
        التسديد
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>الاسم </TableCell>
              <TableCell>المبلغ </TableCell>
              <TableCell>التاريخ </TableCell>
              <TableCell>ملاحظات </TableCell>
              <TableCell>{/*Actions*/}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payment.length > 0 ? (
              payment
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Paginate data
                .map((p) => (
                  <TableRow key={p._id}>
                    <TableCell>{p.name}</TableCell>
                    <TableCell>{formatAmount(p.amount)}</TableCell>
                    <TableCell>{formatDate(p.date)}</TableCell>
                    <TableCell>{p.description}</TableCell>
                    <TableCell>
                      <IconButton
                        variant="contained"
                        color="primary"
                        sx={{
                          padding: "5px",
                          margin: "5px",
                          color: "#44484e",
                        }}
                        onClick={() => deletePayment(p._id)}
                      >
                        <ClearIcon />
                      </IconButton>

                      <IconButton
                        variant="contained"
                        color="primary"
                        sx={{
                          padding: "5px",
                          margin: "5px",
                          color: "#44484e",
                        }}
                        onClick={() => handleEditItem(p)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  لا توجد بيانات متاحة
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {/* Pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={payment.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ direction: "ltr" }}
        />
      </TableContainer>

      {/* edit dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle
          sx={{ textAlign: "center", fontWeight: "bold", fontSize: "30px" }}
        >
          تعديل
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", margin: "5px" }}>
            <TextField
              label="الاسم"
              name="name"
              value={editPayment.name}
              onChange={handleInputChange}
              fullWidth
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#44484e",
                  },
                  "&:hover fieldset": {
                    borderColor: "#44484e",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#44484e",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#44484e",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#44484e",
                },
              }}
            />
            <TextField
              label="المبلغ"
              name="amount"
              value={editPayment.amount}
              onChange={handleInputChange}
              fullWidth
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#44484e",
                  },
                  "&:hover fieldset": {
                    borderColor: "#44484e",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#44484e",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#44484e",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#44484e",
                },
              }}
            />
            <TextField
              label="التاريخ"
              type="date"
              name="date"
              value={editPayment.date}
              onChange={handleInputChange}
              fullWidth
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#44484e",
                  },
                  "&:hover fieldset": {
                    borderColor: "#44484e",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#44484e",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#44484e",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#44484e",
                },
              }}
            />
            <TextField
              label="ملاحظات"
              name="description"
              value={editPayment.description}
              onChange={handleInputChange}
              fullWidth
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#44484e",
                  },
                  "&:hover fieldset": {
                    borderColor: "#44484e",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#44484e",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#44484e",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#44484e",
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>إغلاق</Button>
          <Button onClick={handleSaveEdit}>حفظ</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default PaymentTable;
