import React, { useState } from "react";
import { Button, TextField, Box } from "@mui/material";

const PasswordPrompt = ({ onPasswordCorrect }) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === "aws5185934") {
      //silverAws
      onPasswordCorrect();
    } else {
      setError("Incorrect password. Please try again.");
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handlePasswordSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        backgroundColor: "white",
        width: "300px",
        padding: "30px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        borderRadius: "8px",
      }}
    >
      <TextField
        type="password"
        label="Password"
        variant="outlined"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={!!error}
        helperText={error}
        autoComplete="password"
        sx={{
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#91C7B1",
            },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#91C7B1",
          },
          width: "100%",
          marginBottom: "10px",
        }}
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        style={{
          backgroundColor: "#91C7B1",
          color: "#FFFFFF",
          border: "1px solid #91C7B1",
          width: "100%",
          marginTop: "10px",
        }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default PasswordPrompt;
