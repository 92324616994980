import React from "react";
import { Routes, Route, HashRouter as Router } from "react-router-dom";
import Home from "./pages/Home";
import Registering from "./pages/Auth/Registering";
import Login from "./pages/Auth/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword.js";
import ResetPassword from "./pages/Auth/ResetPassword.js";
import PageNotFound from "./pages/PageNotFound.js";
import AllCustomer from "./pages/customer/AllCustomer.js";
import AddCustomer from "./pages/customer/AddCustomer.js";
import AllProduct from "./pages/Products/AllProduct.js";
import CreateProduct from "./pages/Products/CreateProduct.js";
import UpdateProduct from "./pages/Products/UpdateProduct.js";
import UpdateCustomer from "./pages/customer/UpdateCustomer.js";
import CreateCategory from "./pages/category/CreateCategory.js";
import Charts from "./pages/Charts.js";
import RequireAuth from "./context/RequireAuth";
import Expenses from './pages/finance/Expenses/Expenses';
import DebtPayment from "./pages/finance/debt&payment/DebtPayment.js";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Registering />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:token" element={<ResetPassword />} />

        <Route
          path="/charts"
          element={
            <RequireAuth adminRequired>
              <Charts />
            </RequireAuth>
          }
        />

        <Route
          path="/product"
          element={
            <RequireAuth>
              <AllProduct />
            </RequireAuth>
          }
        />
        <Route
          path="/createProduct"
          element={
            <RequireAuth>
              <CreateProduct />
            </RequireAuth>
          }
        />
        <Route
          path="product/:slug"
          element={
            <RequireAuth>
              <UpdateProduct />
            </RequireAuth>
          }
        />

        <Route
          path="/customer"
          element={
            <RequireAuth>
              <AllCustomer />
            </RequireAuth>
          }
        />
        <Route
          path="/createCustomer/:slug"
          element={
            <RequireAuth>
              <AddCustomer />
            </RequireAuth>
          }
        />
        <Route
          path="/updateCustomer/:id"
          element={
            <RequireAuth>
              <UpdateCustomer />
            </RequireAuth>
          }
        />

        <Route
          path="/category"
          element={
            <RequireAuth>
              <CreateCategory />
            </RequireAuth>
          }
        />

        <Route
          path="/expenses"
          element={
            <RequireAuth>
              <Expenses />
            </RequireAuth>
          }
        />

        <Route
          path="/debtPayment"
          element={
            <RequireAuth>
              <DebtPayment />
            </RequireAuth>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
