// the code without TablePagination

// import React, { useEffect, useState } from "react";
// import {
//   Container,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Typography,
// } from "@mui/material";
// import axios from "axios";
// import dayjs from "dayjs";

// const apiUrl = process.env.REACT_APP_SERVER_URL;

// const CalculateTable = () => {
//   const [debts, setDebts] = useState([]);
//   const [payments, setPayments] = useState([]);

//   const [summedDebts, setSummedDebts] = useState([]);
//   const [summedPayments, setSummedPayments] = useState([]);

//   // debt data
//   useEffect(() => {
//     fetchDebts();
//   }, []);

//   const fetchDebts = () => {
//     axios
//       .get(`${apiUrl}/debt/getDebt`)
//       .then((response) => {
//         setDebts(response.data || []);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the debt details!", error);
//       });
//   };

//   // Calculate the total debt per day
//   useEffect(() => {
//     if (debts.length > 0) {
//       const debtByDate = debts.reduce((acc, debt) => {
//         const date = debt.date; // Assuming date is in 'YYYY-MM-DD' format
//         if (!acc[date]) {
//           acc[date] = { totalDebt: 0, totalPayment: 0, totalRest: 0 };
//         }
//         acc[date].totalDebt += debt.amount;
//         acc[date].totalPayment += debt.paymentAmount; // Assuming paymentAmount is in the debt data
//         acc[date].totalRest += debt.amount - debt.paymentAmount;
//         return acc;
//       }, {});

//       // Convert the debtByDate object into an array of rows for rendering
//       const summedRows = Object.keys(debtByDate).map((date) => ({
//         date,
//         ...debtByDate[date],
//       }));

//       setSummedDebts(summedRows);
//     }
//   }, [debts]);

//   // payment data
//   useEffect(() => {
//     fetchPayments();
//   }, []);

//   const fetchPayments = () => {
//     axios
//       .get(`${apiUrl}/payment/getPayment`)
//       .then((response) => {
//         setPayments(response.data || []); // Fallback to an empty array
//       })
//       .catch((error) => {
//         console.error(
//           "There was an error fetching the payment details!",
//           error
//         );
//       });
//   };

//   // Calculate total payment per day
//   useEffect(() => {
//     if (payments.length > 0) {
//       const paymentByDate = payments.reduce((acc, payment) => {
//         const date = payment.date; // Assuming date is in 'YYYY-MM-DD' format
//         if (!acc[date]) {
//           acc[date] = { totalPayment: 0 };
//         }
//         acc[date].totalPayment += payment.amount; // Assuming amount is the payment amount
//         return acc;
//       }, {});

//       // Convert the paymentByDate object into an array of rows for rendering
//       const summedRows = Object.keys(paymentByDate).map((date) => ({
//         date,
//         ...paymentByDate[date],
//       }));

//       setSummedPayments(summedRows);
//     }
//   }, [payments]);

//   const formatDate = (date) => {
//     return dayjs(date).format("YYYY-MM-DD");
//   };

//   return (
//     <Container>
//       <Typography
//         variant="h5"
//         gutterBottom
//         fontWeight={"bold"}
//         // marginRight={5}
//         // color={"white"}
//         marginTop={3}
//         marginBottom={1.5}
//         backgroundColor={"white"}
//         color={"black"}
//         textAlign={"center"}
//       >
//         الجرد اليومي
//       </Typography>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell sx={{ textAlign: "center" }}>مجموع الديون</TableCell>
//               <TableCell sx={{ textAlign: "center" }}>مجموع التسديد</TableCell>
//               <TableCell sx={{ textAlign: "center" }}>الباقي</TableCell>
//               <TableCell sx={{ textAlign: "center" }}>التاريخ</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {summedDebts.map((row, index) => {
//               // Find corresponding payment data for the same date
//               const paymentData = summedPayments.find(
//                 (paymentRow) => paymentRow.date === row.date
//               );
//               const totalPayment = paymentData ? paymentData.totalPayment : 0;
//               const totalRest = row.totalDebt - totalPayment;

//               return (
//                 <TableRow key={index}>
//                   <TableCell sx={{ textAlign: "center" }}>
//                     {row.totalDebt}
//                   </TableCell>
//                   <TableCell sx={{ textAlign: "center" }}>
//                     {totalPayment}
//                   </TableCell>
//                   <TableCell sx={{ textAlign: "center" }}>
//                     {totalRest}
//                   </TableCell>
//                   <TableCell sx={{ textAlign: "center" }}>
//                     {formatDate(row.date)}
//                   </TableCell>
//                 </TableRow>
//               );
//             })}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </Container>
//   );
// };

// export default CalculateTable;

// the code with TablePagination
import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_SERVER_URL;

const CalculateTable = () => {
  const [debts, setDebts] = useState([]);
  const [payments, setPayments] = useState([]);

  const [summedDebts, setSummedDebts] = useState([]);
  const [summedPayments, setSummedPayments] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // debt data
  useEffect(() => {
    fetchDebts();
  }, []);

  const fetchDebts = () => {
    axios
      .get(`${apiUrl}/debt/getDebt`)
      .then((response) => {
        setDebts(response.data || []);
      })
      .catch((error) => {
        console.error("There was an error fetching the debt details!", error);
      });
  };

  // Calculate the total debt per day
  useEffect(() => {
    if (debts.length > 0) {
      const debtByDate = debts.reduce((acc, debt) => {
        const date = debt.date; // Assuming date is in 'YYYY-MM-DD' format
        if (!acc[date]) {
          acc[date] = { totalDebt: 0, totalPayment: 0, totalRest: 0 };
        }
        acc[date].totalDebt += debt.amount;
        acc[date].totalPayment += debt.paymentAmount; // Assuming paymentAmount is in the debt data
        acc[date].totalRest += debt.amount - debt.paymentAmount;
        return acc;
      }, {});

      // Convert the debtByDate object into an array of rows for rendering
      const summedRows = Object.keys(debtByDate).map((date) => ({
        date,
        ...debtByDate[date],
      }));

      setSummedDebts(summedRows);
    }
  }, [debts]);

  // payment data
  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = () => {
    axios
      .get(`${apiUrl}/payment/getPayment`)
      .then((response) => {
        setPayments(response.data || []); // Fallback to an empty array
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the payment details!",
          error
        );
      });
  };

  // Calculate total payment per day
  useEffect(() => {
    if (payments.length > 0) {
      const paymentByDate = payments.reduce((acc, payment) => {
        const date = payment.date; // Assuming date is in 'YYYY-MM-DD' format
        if (!acc[date]) {
          acc[date] = { totalPayment: 0 };
        }
        acc[date].totalPayment += payment.amount; // Assuming amount is the payment amount
        return acc;
      }, {});

      // Convert the paymentByDate object into an array of rows for rendering
      const summedRows = Object.keys(paymentByDate).map((date) => ({
        date,
        ...paymentByDate[date],
      }));

      setSummedPayments(summedRows);
    }
  }, [payments]);

  const formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when rows per page changes
  };

  // Paginated data
  const paginatedRows = summedDebts.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Container>
      <Typography
        variant="h5"
        gutterBottom
        fontWeight={"bold"}
        marginTop={3}
        marginBottom={1.5}
        backgroundColor={"white"}
        color={"black"}
        textAlign={"center"}
      >
        الجرد اليومي
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "center" }}>مجموع الديون</TableCell>
              <TableCell sx={{ textAlign: "center" }}>مجموع التسديد</TableCell>
              <TableCell sx={{ textAlign: "center" }}>الباقي</TableCell>
              <TableCell sx={{ textAlign: "center" }}>التاريخ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((row, index) => {
              // Find corresponding payment data for the same date
              const paymentData = summedPayments.find(
                (paymentRow) => paymentRow.date === row.date
              );
              const totalPayment = paymentData ? paymentData.totalPayment : 0;
              const totalRest = row.totalDebt - totalPayment;

              return (
                <TableRow key={index}>
                  <TableCell sx={{ textAlign: "center" }}>
                    {row.totalDebt}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {totalPayment}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {totalRest}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {formatDate(row.date)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={summedDebts.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ direction: "ltr" }}
        />
      </TableContainer>
    </Container>
  );
};

export default CalculateTable;
