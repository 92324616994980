import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";

import { Box, Button, TextField, Typography } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import backgroundImage from "../../assets/image/background2.png";
import axios from "axios";

import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const apiUrl = process.env.REACT_APP_SERVER_URL;

const UpdateCustomer = () => {
  const [customer, setCustomer] = useState(null);
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
 
  const navigate = useNavigate();
  const { id } = useParams();
  
  
  // Fetch customer data based on ID
  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/customer/getCustomer/${id}`
        );
        setCustomer(response.data.customer);
        setName(response.data.customer.name);
        setDate(response.data.customer.date);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("Customer not found");
          toast.error("Customer not found");
        } else {
          console.error("Error fetching customer data:", error);
          toast.error("Failed to fetch customer data");
        }
      }
    };

    fetchCustomer(); 
  }, [id]);

  // Handle form submission to update customer
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formattedDate = new Date(date).toISOString();
      
      // Ensure all required fields are filled
      if (!name || !date ) {
        toast.error("Please fill in all fields");
        return;
      }

      const updatedData = {
        name,
        date: formattedDate,
        
      };

      const { data } = await axios.put(
        `${apiUrl}/customer/updateCustomer/${customer._id}`,
        updatedData
      );

      if (data.success) {
        toast.success(`${name} is updated`);
        navigate("/customer");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error("Error updating customer:", error);
      toast.error("Something went wrong");
    }
  };

  if (!customer) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "600px",
            padding: "20px",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
            boxShadow: "10px 0px 10px rgba(255, 255, 255, 0.3)",
          }}
        >
          <Typography
            gutterBottom
            variant="h3"
            component="div"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#80868e",
            }}
          >
            تعديل زبون {/* UpDate Customer */}
          </Typography>
          {/* customer name */}
          <TextField
            type="text"
            name="name"
            value={name}
            placeholder="الاسم"
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            autoComplete="name"
            autoFocus
            sx={{
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#91C7B1",
                },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#91C7B1",
              },

              width: "100%",
              marginBottom: "10px",
            }}
          />

          <Box sx={{ margin: "10px" }}>
            {/* date */}
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              locale={dayjs.locale("en")}
            >
              <DatePicker
                value={dayjs(date)}
                onChange={(newValue) => setDate(newValue)}
                textField={(props) => <TextField {...props} />}
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #B6BBC4",
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: "1px solid black",
                    },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: "1px solid #91C7B1",
                    },
                  width: "100%",
                  marginBottom: "10px",
                }}
              />
            </LocalizationProvider>
          </Box>
          <Button
            type="submit"
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              fontSize: "25px",
              bgcolor: "#44484e",
              fontWeight: "bold",
              "&:hover": {
                bgcolor: "#80868e",
              },
              width: "50%",
            }}
            onClick={handleUpdate}
          >
            تعديل زبون
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default UpdateCustomer;
