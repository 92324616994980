/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";

const apiUrl = process.env.REACT_APP_SERVER_URL;

const AllProduct = () => {
  const [products, setProducts] = useState([]);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  // for filter
  const [categories, setCategories] = useState([]);

  const [checked, setChecked] = useState([]);
  const [radio, setRadio] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  //get all products
  const getAllProducts = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/product/getProduct`);
      setProducts(data.products);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };

  //lifecycle method
  useEffect(() => {
    getAllProducts();
  }, []);

  // Delete a product
  const handleDelete = (productId) => {
    setDeleteId(productId);
    setConfirmDelete(true);
  };

  // Confirm delete action
  const confirmDeleteAction = async () => {
    try {
      await axios.delete(`${apiUrl}/product/deleteProduct/${deleteId}`);
      setProducts(products.filter((product) => product._id !== deleteId));
      toast.success("Product Deleted Successfully");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
    setConfirmDelete(false);
    setDeleteId(null);
  };

  // filtering functions

  useEffect(() => {
    getAllCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //get all cat
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/category/getCategory`);
      if (data?.success) {
        setCategories(data?.categories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //get products
  const getAllProductsPage = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${apiUrl}/product/productList/${page}`);
      setLoading(false);
      setProducts(data.products);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleFilter = (value, id) => {
    let all = [...checked];
    if (value) {
      all.push(id);
    } else {
      all = all.filter((c) => c !== id);
    }
    setChecked(all);
  };

  useEffect(() => {
    if (!checked.length || !radio.length) getAllProductsPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked.length, radio.length]);

  useEffect(() => {
    if (checked.length || radio.length) filterProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, radio]);

  // useEffect(() => {
  //   if (checked.length || radio.length) {
  //     filterProduct();
  //   } else {
  //     getAllProductsPage();
  //   }
  // }, [checked, radio]); 

  //get filtered product
  const filterProduct = async () => {
    try {
      const { data } = await axios.post(`${apiUrl}/product/productFilters`, {
        checked,
        radio,
      });
      setProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  const handleResetFilters = () => {
    setChecked([]); // Clear checked filters
    getAllProductsPage(); // Fetch all products again
    window.location.reload();
  };
  
  // const handleResetFilters = () => {
  //   setChecked([]); // Clear checked filters
  //   setRadio([]); // Clear radio filters
  //   getAllProductsPage(); // Fetch all products again
  //    window.location.reload();
  // };


  const filteredProducts = products.filter((product) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      product.name.toLowerCase().includes(searchLower) ||
      product.itemCode.toLowerCase().includes(searchLower)
    );
  });

  return (
    <Layout>
      {/* Search input */}
      <Box
        sx={{ textAlign: "center", marginTop: "10px", marginBottom: "30px" }}
      >
        <TextField
          variant="outlined"
          placeholder="بحث ..."
          // label="Search by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          fullWidth
          sx={{
            backgroundColor: "white",
            color: "black",
            width: "60%",
            marginTop: "25px",
            marginLeft: "50px",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#91C7B1",
              },
            },
          }}
          dir="rtl"
        />
      </Box>

      <Grid container spacing={2}>
        {/* all product */}
        <Grid item xs={12} md={9}>
          <Grid
            container
            spacing={2}
            direction="row"
            marginLeft="50px"
            alignItems="flex-start"
            justify="flex-end"
            style={{ justifyContent: "flex-end" }}
          >
            {/* {products?.map((p) => ( */}
            {filteredProducts.map((p) => (
              <Card
                sx={{
                  maxWidth: 400,
                  width: "50%",
                  padding: 1,
                  margin: 1,
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "5px 5px 10px #ccc",
                }}
                key={p._id}
              >
                <CardMedia
                  sx={{ height: 300, width: "auto" }}
                  image={`${apiUrl}/product/productPhoto/${p._id}`}
                  title="Product Image"
                />
                <CardContent>
                  <Grid
                    container
                    rowSpacing={1}
                    alignItems="center"
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    {/* line one  */}
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        name="name"
                        variant="standard"
                        value={p.name}
                        InputProps={{
                          readOnly: true,
                        }}
                        autoComplete="off"
                        dir="rtl"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        style={{
                          margin: "10px 0 0 60px",
                          fontWeight: "bold",
                        }}
                      >
                        :الاسم{/* Name: */}
                      </Typography>
                    </Grid>
                    {/* line two */}

                    <Grid item xs={8}>
                      <TextField
                        name="itemCode"
                        fullWidth
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={"#" + p.itemCode}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        style={{
                          margin: "10px 0 0 28px",
                          fontWeight: "bold",
                        }}
                      >
                        :رمز المنتج {/* Item Code: */}
                      </Typography>
                    </Grid>
                    {/* line tree */}

                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        name="price"
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        // value={p.price.toLocaleString("en-US") + ",000"}
                        value={`${p.price.toLocaleString("en-IQ", {
                          style: "currency",
                          currency: "IQD",
                        })},000`}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        style={{
                          margin: "10px 0 0 60px",
                          fontWeight: "bold",
                        }}
                      >
                        :السعر {/* Price: */}
                      </Typography>
                    </Grid>
                    {/* line four */}

                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        name="quantity"
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={p.quantity}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        style={{
                          margin: "10px 0 0 60px",
                          fontWeight: "bold",
                        }}
                      >
                        :العدد {/* Quantity: */}
                      </Typography>
                    </Grid>
                    {/* line five */}

                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        name="wight"
                        variant="standard"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={`${p.wight} G`} //{p.wight}
                        autoComplete="off"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        style={{
                          margin: "10px 0 0 60px",
                          fontWeight: "bold",
                        }}
                      >
                        :الوزن {/* Wight: */}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <IconButton
                    onClick={() => handleDelete(p._id)}
                    aria-label="Delete"
                    title="خذف منتج"
                  >
                    <LayersClearIcon />
                  </IconButton>
                  <Link
                    key={p._id}
                    to={`/product/${p.slug}`}
                    className="product-link"
                  >
                    <IconButton aria-label="Edit Product" title="تعديل المنتج">
                      <NoteAltIcon />
                    </IconButton>
                  </Link>
                  <Link
                    to={`/createCustomer/${p.slug}`}
                    className="product-link"
                  >
                    <IconButton aria-label="Add Customer" title="اضافة زبون">
                      <PersonAddAlt1Icon />
                    </IconButton>
                  </Link>
                </CardActions>
              </Card>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={8}
          md={2}
          sx={{
            position: "sticky",
            backgroundColor: "#ffff",
            height: "100%",
            marginTop: "10px",
            marginRight: "20px",
            marginLeft: "auto",
            borderRadius: "5px",
            justifyContent: "flex-end",
            direction: "rtl",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{ fontWeight: "bold", marginRight: "30px" }}
            >
            اضافة منتج
            </Typography>
            <Link to={"/createProduct"} className="product-link">
              <IconButton
                aria-label="delete"
                sx={{
                  marginBottom: "10px",
                  marginRight: "85px",
                }}
                title="اضافة منتج"
              >
                <AddToQueueIcon sx={{ width: "50px", height: "50px" }} />
              </IconButton>
            </Link>
            <Typography
              variant="h5"
              component="h2"
              sx={{ fontWeight: "bold", marginRight: "30px" }}
            >
              تصفية حسب الفئة {/* Filter By Category */}
            </Typography>
            <Box sx={{ flex: 1, overflowY: "auto" }}>
              {categories?.map((c) => (
                <FormGroup key={c._id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={`category-${c._id}`}
                        onChange={(e) => handleFilter(e.target.checked, c._id)}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                          marginLeft: "10px",
                        }}
                      />
                    }
                    label={c.name}
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: 16,
                      },
                    }}
                  />
                </FormGroup>
              ))}
              <Button
                variant="outlined"
                color="error"
                onClick={handleResetFilters}
                sx={{
                  fontSize: "18px",
                  marginRight: "65px",
                  marginTop: "20px",
                  marginBottom: "20px",
                  border: "2px solid #44484e",
                  color: "#44484e",
                  width: "100px",
                  "&:hover": {
                    border: "2px solid #80868e",
                    bgcolor: "#edf0f4",
                    color: "black",
                  },
                }}
              >
                تصفير {/* RESET */}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* Confirmation dialog */}
      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setConfirmDelete(false)} color="#44484e">
            <CloseIcon />
          </IconButton>
          <IconButton onClick={confirmDeleteAction} color="#44484e" autoFocus>
            <DeleteIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default AllProduct;
