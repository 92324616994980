import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Box, styled } from "@mui/material";

import { Toaster } from "react-hot-toast";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Box component="main" sx={{ minHeight: "90vh", flexGrow: 1, p: 2 }}>
        <DrawerHeader />
        <Toaster />
        {children}
      </Box>
      <Footer />
    </>
  );
};

export default Layout;
