import React, { useState } from "react";
import {
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import axios from "axios";

const apiUrl = process.env.REACT_APP_SERVER_URL;

const AddExpenses = () => {
  const [expressData, setExpressData] = useState({
    amount: "",
    description: "",
    date: dayjs().format("YYYY-MM-DD"),
  });
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExpressData({ ...expressData, [name]: value });
  };

  const handleAddExpenses = async () => {
    // Validate inputs
    if (!expressData.amount || !expressData.description || !expressData.date) {
      setError("All fields are required.");
      return;
    }

    try {
      const response = await axios.post(
        `${apiUrl}/expenses/createExpense`,
        expressData
      ); // Adjust endpoint if needed
      console.log("expenses added:", response.data);
      setError(""); // Clear error on success
      // Optionally reset the form
      setExpressData({ amount: "", description: "", date: "" });
      window.location.reload();
    } catch (err) {
      setError(err.response?.data?.message || "Failed to add expenses.");
    }
  };
  return (
    <Paper
      sx={{
        padding: "18px",
        textAlign: "center",
        width: "40%",
      }}
    >
      <Typography variant="h5" gutterBottom fontWeight={"bold"}>
        اضافة مصرف
      </Typography>
      {error && <Typography color="error">{error}</Typography>}

      <FormControl fullWidth margin="normal">
        <TextField
          label="المبلغ"
          name="amount"
          value={expressData.amount}
          onChange={handleInputChange}
          variant="standard"
          InputProps={{
            style: { textAlign: "right" },
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
          InputLabelProps={{ style: { right: 30, left: "auto" } }}
          sx={{
            "& .MuiInput-underline": {
              "&:before": {
                borderBottomColor: "#44484e",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#44484e",
              },
              "&:after": {
                borderBottomColor: "#44484e",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#44484e",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#44484e",
            },
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          label="ملاحظات"
          name="description"
          value={expressData.description}
          onChange={handleInputChange}
          variant="standard"
          InputProps={{
            style: { textAlign: "right" },
          }}
          InputLabelProps={{ style: { right: 30, left: "auto" } }}
          sx={{
            "& .MuiInput-underline": {
              "&:before": {
                borderBottomColor: "#44484e",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#44484e",
              },
              "&:after": {
                borderBottomColor: "#44484e",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#44484e",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#44484e",
            },
          }}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <TextField
          type="date"
          name="date"
          value={expressData.date}
          onChange={handleInputChange}
          variant="standard"
          InputProps={{ style: { textAlign: "right" } }}
          InputLabelProps={{
            style: { right: 30, left: "auto" },
            shrink: true,
          }}
          sx={{
            "& .MuiInput-underline": {
              "&:before": {
                borderBottomColor: "#44484e",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottomColor: "#44484e",
              },
              "&:after": {
                borderBottomColor: "#44484e",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#44484e",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#44484e",
            },
          }}
        />
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        onClick={handleAddExpenses}
        sx={{
          background: "#44484e",
          fontWeight: "bold",
          fontSize: "15px",
          margin: "20px 0 10px 0",
          "&:hover": {
            backgroundColor: "#5c6169",
          },
        }}
      >
        اضافة مصرف
      </Button>
    </Paper>
  );
};

export default AddExpenses;
