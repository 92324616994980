import React from "react";
import Layout from "../components/layout/Layout";
import ProductCarousel from "../components/ProductCarousel"; 

const Home = () => {
  return (
    <Layout>
      <ProductCarousel />
    </Layout>
  );
};

export default Home;
