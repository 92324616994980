import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import toast from "react-hot-toast";
import axios from "axios";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import backgroundImage from "../../assets/image/background2.png";

const apiUrl = process.env.REACT_APP_SERVER_URL;

// Function to format amount
const formatAmount = (amount) => {
  const parsedAmount = parseFloat(amount);
  return isNaN(parsedAmount)
    ? "0.000"
    : parsedAmount.toLocaleString("en-US", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
};

const AddCustomer = () => {
  const [name, setName] = useState("");
  const [date, setDate] = useState(dayjs());
  const [quantity, setQuantity] = useState("");
  const [wight, setWight] = useState("");
  const [price, setPrice] = useState("");
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [product, setProduct] = useState(null);

  const navigate = useNavigate();
  const params = useParams();

  const getSingleProduct = useCallback(async () => {
    try {
      // console.log(params.slug); 
      const { data } = await axios.get(
        `${apiUrl}/product/${params.slug}`
      );
      // console.log("Fetched product data:", data);
      
      setProduct(data.product);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  }, [params.slug]);

  useEffect(() => {
    if (params.slug) {
      getSingleProduct();
    }
  }, [params.slug, getSingleProduct]);

  useEffect(() => {
    // Calculate total price whenever price or quantity changes
    const calcTotalPrice = () => {
      const parsedPrice = parseFloat(price) || 0;
      const parsedQuantity = parseInt(quantity) || 0;
      const totalPriceBeforeDiscount = parsedPrice * parsedQuantity;
      const parsedDiscount = parseFloat(discount) || 0;
      const discountedPrice = totalPriceBeforeDiscount - parsedDiscount;
      setTotalPrice(discountedPrice);
    };

    calcTotalPrice();
  }, [price, quantity, discount]);

  
const handleCreate = async (e) => {
  e.preventDefault();
  try {
    const formattedDate = date.toISOString();
    const discountAmount = parseFloat(discount);
    const customerData = {
      name,
      date: formattedDate,
      quantity: parseInt(quantity),
      wight: parseInt(wight),
      price: totalPrice,
      discount: discountAmount,
      product: product?._id,
    };

    const { data } = await axios.post(
      `${apiUrl}/customer/createCustomer`,
      customerData
    );

    if (data?.success) {
      toast.success("Customer Created Successfully");
      navigate("/customer");
    } else {
      toast.error(data?.message);
    }
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
  }
};


  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "600px",
            padding: "20px",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
            boxShadow: "10px 0px 10px rgba(255, 255, 255, 0.3)",
          }}
        >
          <Typography
            gutterBottom
            variant="h3"
            component="div"
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#80868e",
            }}
          >
            اضافة زبون {/* Create Customer */}
          </Typography>
          <TextField
            type="text"
            name="name"
            value={name}
            placeholder="الاسم"
            onChange={(e) => setName(e.target.value)}
            variant="outlined"
            autoComplete="name"
            autoFocus
            sx={{
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#91C7B1",
                },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#91C7B1",
              },
              width: "100%",
              marginBottom: "10px",
            }}
          />

          {product && (
            <Grid item xs={12} md={6}>
              <div className="text-center">
                <img
                  src={`${apiUrl}/product/productPhoto/${product._id}`}
                  alt="product_photo"
                  height={200}
                  className="img img-responsive"
                />
              </div>
            </Grid>
          )}

          <Box sx={{ margin: "10px" }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              locale={dayjs.locale("en")}
            >
              <DatePicker
                value={date}
                onChange={(newValue) => setDate(newValue)}
                slots={{
                  textField: (params) => (
                    <TextField
                      {...params}
                      sx={{
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            border: "1px solid #B6BBC4",
                          },
                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: "1px solid black",
                          },
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: "1px solid #91C7B1",
                          },
                        "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "#91C7B1",
                          },
                        width: "100%",
                        marginBottom: "10px",
                      }}
                    />
                  ),
                }}
              />
            </LocalizationProvider>

            {product && (
              <TextField
                type="text"
                name="itemCode"
                value={product.itemCode}
                placeholder="رمز المنتج"
                fullWidth
                variant="outlined"
                autoComplete="off"
                autoFocus
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#91C7B1",
                    },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#91C7B1",
                  },
                  width: "100%",
                  marginBottom: "10px",
                }}
              />
            )}
            <TextField
              type="text"
              name="quantity"
              value={quantity}
              placeholder="العدد"
              onChange={(e) => setQuantity(e.target.value)}
              fullWidth
              variant="outlined"
              autoComplete="quantity"
              autoFocus
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#91C7B1",
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#91C7B1",
                },
                width: "100%",
                marginBottom: "10px",
              }}
            />
            <TextField
              type="text"
              name="price"
              value={price}
              placeholder="السعر"
              onChange={(e) => setPrice(e.target.value)}
              fullWidth
              variant="outlined"
              autoComplete="price"
              autoFocus
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#91C7B1",
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#91C7B1",
                },
                width: "100%",
                marginBottom: "10px",
              }}
            />
            <TextField
              type="text"
              name="discount"
              value={discount}
              placeholder="خصم"
              onChange={(e) => setDiscount(e.target.value)}
              fullWidth
              variant="outlined"
              autoComplete="discount"
              autoFocus
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#91C7B1",
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#91C7B1",
                },
                width: "100%",
                marginBottom: "10px",
              }}
            />
            <TextField
              type="text"
              name="wight"
              value={wight}
              placeholder="الوزن"
              onChange={(e) => setWight(e.target.value)}
              fullWidth
              variant="outlined"
              autoComplete="wight"
              autoFocus
              sx={{
                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#91C7B1",
                  },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#91C7B1",
                },
                width: "100%",
                marginBottom: "10px",
              }}
            />

            <Typography
              variant="h6"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#80868e",
                marginTop: "20px",
              }}
            >
              السعر الكلي: {/* Total Price */}
            </Typography>
            <Typography
              variant="h5"
              component="div"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#80868e",
              }}
            >
              {formatAmount(totalPrice)} {/* Display formatted total price */}
            </Typography>
            <Button
              variant="outlined"
              onClick={handleCreate}
              style={{
                backgroundColor: "#91C7B1",
                color: "#FFFFFF",
                border: "1px solid #91C7B1",
                width: "100%",
                marginTop: "10px",
              }}
            >
              اضافة زبون {/* Create Customer */}
            </Button>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default AddCustomer;



