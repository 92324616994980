/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import toast from "react-hot-toast";
import axios from "axios";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import backgroundImage from "../../assets/image/background2.png";

const apiUrl = process.env.REACT_APP_SERVER_URL;

// const UpdateProduct = () => {
//   const [categories, setCategories] = useState([]);
//   const [name, setName] = useState("");
//   const [itemCode, setItemCode] = useState("");
//   const [price, setPrice] = useState("");
//   const [quantity, setQuantity] = useState("");
//   const [wight, setWight] = useState("");
//   const [category, setCategory] = useState("");
//   const [photo, setPhoto] = useState("");
//   const [id, setId] = useState("");

//   const navigate = useNavigate();
//   const params = useParams();

//   const handleFileChange = (e) => {
//     setPhoto(e.target.files[0]);
//   };

//   // //get single product
//   // const getSingleProduct = async () => {
//   //   try {
//   //     const { data } = await axios.get(`#/product/${params.slug}`);
//   //     setName(data.product.name);
//   //     setId(data.product._id);
//   //     setItemCode(data.product.itemCode);
//   //     setPrice(data.product.price);
//   //     setQuantity(data.product.quantity);
//   //     setWight(data.product.wight);
//   //     setCategory(data.product.category._id);
//   //   } catch (error) {
//   //     console.log(error);
//   //   }
//   // };
//   const getSingleProduct = async () => {
//     try {
//       const { data } = await axios.get(`#/product/${params.slug}`);
//         console.log(data);
//       if (data && data.product) {
//         setName(data.product.name);
//         setId(data.product._id);
//         setItemCode(data.product.itemCode);
//         setPrice(data.product.price);
//         setQuantity(data.product.quantity);
//         setWight(data.product.wight);
//         setCategory(data.product.category._id);
//       } else {
//         console.log("Product data not found");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   // useEffect(() => {
//   //   getSingleProduct();
//   //   //eslint-disable-next-line
//   // }, []);

//   useEffect(() => {
//     getSingleProduct();
//     console.log({ name, itemCode, price, quantity, wight, category });
//   }, [name, itemCode, price, quantity, wight, category]); // Log after fetching the product

//   //get all Categories
//   const getAllCategory = async () => {
//     try {
//       const { data } = await axios.get(`${apiUrl}/category/getCategory`);
//       if (data?.success) {
//         setCategories(data?.categories);
//       } else {
//         console.error(
//           "API response does not contain the expected 'categories' property:",
//           data
//         );
//         toast.error("API response format is incorrect");
//       }
//     } catch (error) {
//       console.error(error);
//       toast.error("Something went wrong in getting category");
//     }
//   };

//   useEffect(() => {
//     getAllCategory();
//   }, []);

//   //update product function
//   const handleUpdate = async (e) => {
//     e.preventDefault();
//     try {
//       const productData = new FormData();
//       productData.append("name", name);
//       productData.append("itemCode", itemCode);
//       productData.append("price", price);
//       productData.append("quantity", quantity);
//       productData.append("wight", wight);
//       photo && productData.append("photo", photo);
//       productData.append("category", category);
//       const { data } = await axios.put(
//         `${apiUrl}/product/updateProduct/${id}`,
//         productData
//       );
//       if (data?.success) {
//         toast.success("Product upDate Successfully");
//         navigate("/product");
//       } else {
//         toast.error(data?.message);
//       }
//     } catch (error) {
//       console.log(error);
//       toast.error("something went wrong");
//     }
//   };
const UpdateProduct = () => {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [wight, setWight] = useState("");
  const [category, setCategory] = useState("");
  const [photo, setPhoto] = useState("");
  const [id, setId] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
  };

  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/product/${params.slug}`);
      console.log("Fetched product data:", data);

      if (data && data.product) {
        setName(data.product.name);
        setId(data.product._id);
        setItemCode(data.product.itemCode);
        setPrice(data.product.price);
        setQuantity(data.product.quantity);
        setWight(data.product.wight);
        setCategory(data.product.category._id);
      } else {
        console.log("Product data not found");
      }
    } catch (error) {
      console.log("Error fetching product:", error);
    }
  };

  useEffect(() => {
    getSingleProduct();
  }, [params.slug]);

  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/category/getCategory`);
      if (data?.success) {
        setCategories(data?.categories);
      } else {
        console.error(
          "API response does not contain the expected 'categories' property:",
          data
        );
        toast.error("API response format is incorrect");
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong in getting category");
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const productData = new FormData();
      productData.append("name", name);
      productData.append("itemCode", itemCode);
      productData.append("price", price);
      productData.append("quantity", quantity);
      productData.append("wight", wight);
      photo && productData.append("photo", photo);
      productData.append("category", category);

      const { data } = await axios.put(
        `${apiUrl}/product/updateProduct/${id}`,
        productData
      );
      if (data?.success) {
        toast.success("Product updated successfully");
        navigate("/product");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "600px",
            padding: "20px",
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
            boxShadow: "10px 0px 10px rgba(255, 255, 255, 0.3)",
          }}
        >
          <Typography
            gutterBottom
            variant="h4"
            component="div"
            style={{ textAlign: "center", fontWeight: "bold" }}
          >
            تعديل المنتج {/* Update Product */}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "600px",
                padding: "20px",
              }}
            >
              <FormControl>
                <Select
                  id="category-select"
                  name="category"
                  labelId="category-label"
                  value={category}
                  onChange={(event) => setCategory(event.target.value)}
                  displayEmpty
                  sx={{ width: "500px" }}
                >
                  <MenuItem disabled value="">
                    {/* <em>Select a category</em> */}
                    <em>اختر فئة</em>
                  </MenuItem>
                  {categories?.map((c) => (
                    <MenuItem key={c._id} value={c._id}>
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12} md={6} sx={{ margin: "30px 0 10px 100px" }}>
                  <input
                    type="file"
                    accept="image/*"
                    id="photo-input"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="photo-input">
                    <Button variant="outlined" component="span" fullWidth>
                      {photo ? photo.name : "Upload Photo"}
                    </Button>
                  </label>
                </Grid>
                {/* photo */}
                <Grid item xs={12} md={6}>
                  <div className="text-center">
                    {photo ? (
                      <img
                        src={URL.createObjectURL(photo)}
                        alt="product_photo"
                        height={200}
                        className="img img-responsive"
                      />
                    ) : id ? (
                      <img
                        src={`${apiUrl}/product/productPhoto/${id}`}
                        alt="product_photo"
                        height={200}
                        className="img img-responsive"
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop
                          e.target.src = "path/to/placeholder-image.png"; // Set a placeholder image
                        }}
                      />
                    ) : null}
                  </div>
                </Grid>
              </Grid>
              <Box sx={{ margin: "10px" }}>
                <TextField
                  type="text"
                  name="name"
                  value={name}
                  id="name"
                  placeholder="الاسم" //"Write a name"
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  autoComplete="on"
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#91C7B1",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#91C7B1",
                    },

                    width: "100%",
                    marginBottom: "10px",
                  }}
                />

                <TextField
                  type="text"
                  name="itemCode"
                  value={itemCode}
                  id="itemCode"
                  placeholder="رمز المنتج" //"Write a itemCode"
                  onChange={(e) => setItemCode(e.target.value)}
                  fullWidth
                  variant="outlined"
                  autoComplete="on"
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#91C7B1",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#91C7B1",
                    },

                    width: "100%",
                    marginBottom: "10px",
                  }}
                />

                <TextField
                  type="text"
                  name="price"
                  value={price}
                  id="price"
                  placeholder="السعر" //"Write a price"
                  onChange={(e) => setPrice(e.target.value)}
                  fullWidth
                  variant="outlined"
                  autoComplete="on"
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#91C7B1",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#91C7B1",
                    },

                    width: "100%",
                    marginBottom: "10px",
                  }}
                />

                <TextField
                  type="text"
                  name="quantity"
                  value={quantity}
                  id="quantity"
                  placeholder="العدد" //"Write a quantity"
                  onChange={(e) => setQuantity(e.target.value)}
                  fullWidth
                  variant="outlined"
                  autoComplete="on"
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#91C7B1",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#91C7B1",
                    },

                    width: "100%",
                    marginBottom: "10px",
                  }}
                />

                <TextField
                  type="text"
                  name="wight"
                  value={wight}
                  id="wight"
                  placeholder="الوزن" //"Write a wight"
                  onChange={(e) => setWight(e.target.value)}
                  fullWidth
                  variant="outlined"
                  autoComplete="on"
                  autoFocus
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#91C7B1",
                      },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#91C7B1",
                    },

                    width: "100%",
                    marginBottom: "10px",
                  }}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontSize: "25px",
                  bgcolor: "#44484e",
                  "&:hover": {
                    bgcolor: "#80868e",
                  },
                  width: "50%",
                }}
                onClick={handleUpdate}
              >
                تعديل المنتج{/* Update Product */}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default UpdateProduct;


// the old photo code 
// {
//   photo ? (
//     <Grid item xs={12} md={6}>
//       <div className="text-center">
//         <img
//           src={URL.createObjectURL(photo)}
//           alt="product_photo"
//           height={200}
//           className="img img-responsive"
//         />
//       </div>
//     </Grid>
//   ) : id ? (
//     <Grid item xs={12} md={6}>
//       <div className="text-center">
//         <img
//           src={`/product/productPhoto/${id}`}
//           alt="product_photo"
//           height={200}
//           className="img img-responsive"
//         />
//       </div>
//     </Grid>
//   ) : null;
// }
