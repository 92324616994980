import { Box, Button, TextField } from "@mui/material";
import React from "react";

const CategoryForm = ({ handleSubmit, value, setValue }) => {
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        mt: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      <TextField
        required
        id="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        name="text"
        autoComplete="text"
        autoFocus
        sx={{
          width: { xs: "100%", sm: "80%", md: "300%", lg: "400%" },
          maxWidth: "400px",
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              borderColor: "#91C7B1",
            },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#91C7B1",
          },
        }}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{
          mt: 3,
          mb: 2,
          width: "100%",
          fontSize: { xs: "16px", sm: "20px", md: "25px" },
          bgcolor: "#44484e",
          "&:hover": {
            bgcolor: "#80868e",
          },
        }}
      >
        اضافة
      </Button>
    </Box>
  );
};

export default CategoryForm;
