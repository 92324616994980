/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Container,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextField,
//   Typography,
// } from "@mui/material";
// import ClearIcon from "@mui/icons-material/Clear";
// import EditIcon from "@mui/icons-material/Edit";
// import axios from "axios";
// import dayjs from "dayjs";

// const apiUrl = process.env.REACT_APP_SERVER_URL;

// const ExpensesTable = () => {
//   const [expenses, setExpenses] = useState([]);
//   const [editDialogOpen, setEditDialogOpen] = useState(false);
//   const [editExpenses, setEditExpenses] = useState({
//     _id: "",
//     amount: "",
//     description: "",
//     date: "",
//   });
  
//   useEffect(() => {
//     fetchExpenses();
//   }, []);

//   const fetchExpenses = () => {
//     axios
//       .get(`${apiUrl}/expenses/getExpense`)
//       .then((response) => {
//         setExpenses(response.data || []);
//       })
//       .catch((error) => {
//         console.error(
//           "There was an error fetching the expenses details!",
//           error
//         );
//       });
//   };

//   const deleteExpenses = (id) => {
//     if (window.confirm("هل أنت متأكد أنك تريد حذف هذا العنصر؟")) {
//       axios
//         .delete(`${apiUrl}/expenses/deleteExpense/${id}`)
//         .then(() => {
//           // Remove the deleted payment from the state
//           setExpenses((prevExpenses) =>
//             prevExpenses.filter((e) => e._id !== id)
//           );
//           alert("تم حذف العنصر بنجاح");
//         })
//         .catch((error) => {
//           console.error("حدث خطأ أثناء حذف العنصر!", error);
//           alert("فشل في الحذف. حاول مرة أخرى.");
//         });
//     }
//   };

//   const formatDate = (date) => {
//     return dayjs(date).format("YYYY-MM-DD");
//   };

//   const formatAmount = (amount) => {
//     const parsedAmount = parseFloat(amount);
//     return isNaN(parsedAmount)
//       ? "0.00"
//       : parsedAmount.toLocaleString("en-US", {
//           minimumFractionDigits: 3,
//           maximumFractionDigits: 3,
//         });
//   };

//   // Function to handle editing an item
//   const handleEditItem = (debt) => {
//     setEditExpenses({
//       _id: debt._id,
//       amount: debt.amount,
//       description: debt.description,
//       date: formatDate(debt.date),
//     });
//     setEditDialogOpen(true);
//   };

//   // Function to handle saving the edited payment
//   const handleSaveEdit = () => {
//     const { _id, amount, date, description } = editExpenses;

//     axios
//       .put(`${apiUrl}/expenses/updateExpense/${_id}`, {
//         amount,
//         date,
//         description,
//       })
//       .then(() => {
//         setExpenses((prevExpenses) =>
//           prevExpenses.map((e) =>
//             e._id === _id
//               ? {
//                   ...e,
//                   amount,
//                   date: dayjs(date).toISOString(),
//                   description,
//                 }
//               : e
//           )
//         );
//         setEditDialogOpen(false);
//         alert("تم التعديل بنجاح");
//         window.location.reload();
//       })
//       .catch((error) => {
//         console.error("حدث خطأ أثناء تعديل البيانات!", error);
//         alert("فشل في التعديل. حاول مرة أخرى.");
//       });
//   };
//   // Update the state when form inputs change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setEditExpenses((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

  

  

//   return (
//     <Container>
//       <Typography
//         variant="h5"
//         gutterBottom
//         fontWeight={"bold"}
//         marginRight={5}
//         marginTop={3}
//         marginBottom={1.5}
//       >
//         الديون
//       </Typography>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>المبلغ </TableCell>
//               <TableCell>التاريخ </TableCell>
//               <TableCell>ملاحظات </TableCell>
//               <TableCell>{/*Actions*/}</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {expenses.length > 0 ? (
//               expenses.map((e) => (
//                 <TableRow key={e._id}>
//                   <TableCell>{formatAmount(e.amount)}</TableCell>
//                   <TableCell>{formatDate(e.date)}</TableCell>
//                   <TableCell>{e.description}</TableCell>
//                   <TableCell>
//                     <IconButton
//                       variant="contained"
//                       color="primary"
//                       sx={{
//                         padding: "5px",
//                         margin: "5px",
//                         color: "#44484e",
//                       }}
//                       onClick={() => deleteExpenses(e._id)}
//                     >
//                       <ClearIcon />
//                     </IconButton>

//                     <IconButton
//                       variant="contained"
//                       color="primary"
//                       sx={{
//                         padding: "5px",
//                         margin: "5px",
//                         color: "#44484e",
//                       }}
//                       onClick={() => handleEditItem(e)}
//                     >
//                       <EditIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={5} align="center">
//                   لا توجد بيانات متاحة
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {/* edit dialog */}
//       <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
//         <DialogTitle
//           sx={{ textAlign: "center", fontWeight: "bold", fontSize: "30px" }}
//         >
//           تعديل
//         </DialogTitle>
//         <DialogContent>
//           <Box sx={{ display: "flex", flexDirection: "column", margin: "5px" }}>
//             <TextField
//               label="المبلغ"
//               name="amount"
//               value={editExpenses.amount}
//               onChange={handleInputChange}
//               fullWidth
//               sx={{
//                 marginBottom: "20px",
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#44484e",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   color: "#44484e",
//                 },
//                 "& .MuiInputLabel-root.Mui-focused": {
//                   color: "#44484e",
//                 },
//               }}
//             />
//             <TextField
//               label="التاريخ"
//               type="date"
//               name="date"
//               value={editExpenses.date}
//               onChange={handleInputChange}
//               fullWidth
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               sx={{
//                 marginBottom: "20px",
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#44484e",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   color: "#44484e",
//                 },
//                 "& .MuiInputLabel-root.Mui-focused": {
//                   color: "#44484e",
//                 },
//               }}
//             />
//             <TextField
//               label="ملاحظات"
//               name="description"
//               value={editExpenses.description}
//               onChange={handleInputChange}
//               fullWidth
//               sx={{
//                 marginBottom: "20px",
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#44484e",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   color: "#44484e",
//                 },
//                 "& .MuiInputLabel-root.Mui-focused": {
//                   color: "#44484e",
//                 },
//               }}
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => setEditDialogOpen(false)}
//             sx={{
//               backgroundColor: "#f6f6f6",
//               color: "#44484e",
//               fontWeight: "bold",
//               "&:hover": {
//                 backgroundColor: "#f6f6f6",
//                 color: "#44484e",
//               },
//             }}
//           >
//             إغلاق
//           </Button>
//           <Button
//             onClick={handleSaveEdit}
//             sx={{
//               backgroundColor: "#44484e",
//               color: "white",
//               fontWeight: "bold",
//               "&:hover": {
//                 backgroundColor: "#44484e",
//                 color: "white",
//               },
//             }}
//           >
//             حفظ
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// };

// export default ExpensesTable;


  /* the code with daily sum table */


// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Container,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextField,
//   Typography,
// } from "@mui/material";
// import ClearIcon from "@mui/icons-material/Clear";
// import EditIcon from "@mui/icons-material/Edit";
// import axios from "axios";
// import dayjs from "dayjs";

// const apiUrl = process.env.REACT_APP_SERVER_URL;

// const ExpensesTable = () => {
//   const [expenses, setExpenses] = useState([]);
//   const [groupedExpenses, setGroupedExpenses] = useState({});
//   const [editDialogOpen, setEditDialogOpen] = useState(false);
//   const [editExpenses, setEditExpenses] = useState({
//     _id: "",
//     amount: "",
//     description: "",
//     date: "",
//   });

  
//   useEffect(() => {
//     fetchExpenses();
//   }, []);

//   const fetchExpenses = () => {
//     axios
//       .get(`${apiUrl}/expenses/getExpense`)
//       .then((response) => {
//         const data = response.data || [];
//         setExpenses(data);
//         groupExpensesByDate(data);
//       })
//       .catch((error) => {
//         console.error(
//           "There was an error fetching the expenses details!",
//           error
//         );
//       });
//   };

//   const groupExpensesByDate = (expenses) => {
//     const grouped = expenses.reduce((acc, expense) => {
//       const date = dayjs(expense.date).format("YYYY-MM-DD");
//       if (!acc[date]) {
//         acc[date] = [];
//       }
//       acc[date].push(expense);
//       return acc;
//     }, {});
//     setGroupedExpenses(grouped);
//   };

//   const deleteExpenses = (id) => {
//     if (window.confirm("هل أنت متأكد أنك تريد حذف هذا العنصر؟")) {
//       axios
//         .delete(`${apiUrl}/expenses/deleteExpense/${id}`)
//         .then(() => {
//           setExpenses((prevExpenses) =>
//             prevExpenses.filter((e) => e._id !== id)
//           );
//           alert("تم حذف العنصر بنجاح");
//           window.location.reload();
//         })
//         .catch((error) => {
//           console.error("حدث خطأ أثناء حذف العنصر!", error);
//           alert("فشل في الحذف. حاول مرة أخرى.");
//         });
//     }
//   };

//   const formatDate = (date) => {
//     return dayjs(date).format("YYYY-MM-DD");
//   };

//   const formatAmount = (amount) => {
//     const parsedAmount = parseFloat(amount);
//     return isNaN(parsedAmount)
//       ? "0.00"
//       : parsedAmount.toLocaleString("en-US", {
//           minimumFractionDigits: 3,
//           maximumFractionDigits: 3,
//         });
//   };

//   // Function to handle editing an item
//   const handleEditItem = (debt) => {
//     setEditExpenses({
//       _id: debt._id,
//       amount: debt.amount,
//       description: debt.description,
//       date: formatDate(debt.date),
//     });
//     setEditDialogOpen(true);
//   };

//   // Function to handle saving the edited payment
//   const handleSaveEdit = () => {
//     const { _id, amount, date, description } = editExpenses;

//     axios
//       .put(`${apiUrl}/expenses/updateExpense/${_id}`, {
//         amount,
//         date,
//         description,
//       })
//       .then(() => {
//         setExpenses((prevExpenses) =>
//           prevExpenses.map((e) =>
//             e._id === _id
//               ? {
//                   ...e,
//                   amount,
//                   date: dayjs(date).toISOString(),
//                   description,
//                 }
//               : e
//           )
//         );
//         setEditDialogOpen(false);
//         alert("تم التعديل بنجاح");
//         window.location.reload();
//       })
//       .catch((error) => {
//         console.error("حدث خطأ أثناء تعديل البيانات!", error);
//         alert("فشل في التعديل. حاول مرة أخرى.");
//       });
//   };

//   // Update the state when form inputs change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setEditExpenses((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   // Calculate the total amount for each day
//   const calculateTotalAmountForDate = (date) => {
//     const expensesForDate = groupedExpenses[date] || [];
//     return expensesForDate.reduce(
//       (total, expense) => total + parseFloat(expense.amount),
//       0
//     );
//   };

  

  

//   return (
//     <Container>
//       <Typography
//         variant="h5"
//         gutterBottom
//         fontWeight={"bold"}
//         // marginRight={5}
//         marginTop={3}
//         marginBottom={1.5}
//         backgroundColor={"white"}
//         color={"black"}
//         textAlign={"center"}
//       >
//         المصرف اليومي
//       </Typography>
//       {/* Expenses Table */}
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>المبلغ </TableCell>
//               <TableCell>التاريخ </TableCell>
//               <TableCell>ملاحظات </TableCell>
//               <TableCell>{/*Actions*/}</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {Object.keys(groupedExpenses).length > 0 ? (
//               Object.keys(groupedExpenses).map((date) => (
//                 <React.Fragment key={date}>
//                   {groupedExpenses[date].map((e) => (
//                     <TableRow key={e._id}>
//                       <TableCell>{formatAmount(e.amount)}</TableCell>
//                       <TableCell>{formatDate(e.date)}</TableCell>
//                       <TableCell>{e.description}</TableCell>
//                       <TableCell>
//                         <IconButton
//                           variant="contained"
//                           color="primary"
//                           sx={{
//                             padding: "5px",
//                             margin: "5px",
//                             color: "#44484e",
//                           }}
//                           onClick={() => deleteExpenses(e._id)}
//                         >
//                           <ClearIcon />
//                         </IconButton>

//                         <IconButton
//                           variant="contained"
//                           color="primary"
//                           sx={{
//                             padding: "5px",
//                             margin: "5px",
//                             color: "#44484e",
//                           }}
//                           onClick={() => handleEditItem(e)}
//                         >
//                           <EditIcon />
//                         </IconButton>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//                 </React.Fragment>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={5} align="center">
//                   لا توجد بيانات متاحة
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {/* Total Amount by Date Table */}
//       <Typography
//         variant="h6"
//         gutterBottom
//         fontWeight={"bold"}
//         marginTop={3}
//         marginBottom={1.5}
//         backgroundColor={"white"}
//         color={"black"}
//         textAlign={"center"}
//       >
//         مجموع المصرف اليومي
//       </Typography>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell sx={{ textAlign: "center" }}>المجموع</TableCell>
//               <TableCell sx={{ textAlign: "center" }}>التاريخ</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {Object.keys(groupedExpenses).length > 0 ? (
//               Object.keys(groupedExpenses).map((date) => (
//                 <TableRow key={date}>
//                   <TableCell sx={{ textAlign: "center" }}>
//                     {formatAmount(calculateTotalAmountForDate(date))}
//                   </TableCell>
//                   <TableCell sx={{ textAlign: "center" }}>
//                     {formatDate(date)}
//                   </TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={2} align="center">
//                   لا توجد بيانات متاحة
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {/* Edit Dialog */}
//       <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
//         <DialogTitle
//           sx={{ textAlign: "center", fontWeight: "bold", fontSize: "30px" }}
//         >
//           تعديل
//         </DialogTitle>
//         <DialogContent>
//           <Box sx={{ display: "flex", flexDirection: "column", margin: "5px" }}>
//             <TextField
//               label="المبلغ"
//               name="amount"
//               value={editExpenses.amount}
//               onChange={handleInputChange}
//               fullWidth
//               sx={{
//                 marginBottom: "20px",
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#44484e",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   color: "#44484e",
//                 },
//                 "& .MuiInputLabel-root.Mui-focused": {
//                   color: "#44484e",
//                 },
//               }}
//             />
//             <TextField
//               label="التاريخ"
//               type="date"
//               name="date"
//               value={editExpenses.date}
//               onChange={handleInputChange}
//               fullWidth
//               sx={{
//                 marginBottom: "20px",
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#44484e",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   color: "#44484e",
//                 },
//                 "& .MuiInputLabel-root.Mui-focused": {
//                   color: "#44484e",
//                 },
//               }}
//             />
//             <TextField
//               label="الوصف"
//               name="description"
//               value={editExpenses.description}
//               onChange={handleInputChange}
//               fullWidth
//               multiline
//               rows={4}
//               sx={{
//                 marginBottom: "20px",
//                 "& .MuiOutlinedInput-root": {
//                   "& fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&:hover fieldset": {
//                     borderColor: "#44484e",
//                   },
//                   "&.Mui-focused fieldset": {
//                     borderColor: "#44484e",
//                   },
//                 },
//                 "& .MuiInputLabel-root": {
//                   color: "#44484e",
//                 },
//                 "& .MuiInputLabel-root.Mui-focused": {
//                   color: "#44484e",
//                 },
//               }}
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={() => setEditDialogOpen(false)}
//             sx={{ color: "#44484e" }}
//           >
//             إغلاق
//           </Button>
//           <Button onClick={handleSaveEdit} sx={{ color: "#44484e" }}>
//             حفظ التعديلات
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// };

// export default ExpensesTable; 

/* the code with pages for tables */ 

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_SERVER_URL;

const ExpensesTable = () => {
  const [expenses, setExpenses] = useState([]);
  const [groupedExpenses, setGroupedExpenses] = useState({});
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editExpenses, setEditExpenses] = useState({
    _id: "",
    amount: "",
    description: "",
    date: "",
  });

  // Separate pagination for the two tables
  const [expensesTablePage, setExpensesTablePage] = useState(0);
  const [expensesTableRowsPerPage, setExpensesTableRowsPerPage] = useState(5);

  const [totalAmountTablePage, setTotalAmountTablePage] = useState(0);
  const [totalAmountTableRowsPerPage, setTotalAmountTableRowsPerPage] =
    useState(5);

  useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = () => {
    axios
      .get(`${apiUrl}/expenses/getExpense`)
      .then((response) => {
        const data = response.data || [];
        setExpenses(data);
        groupExpensesByDate(data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the expenses details!",
          error
        );
      });
  };

  const groupExpensesByDate = (expenses) => {
    const grouped = expenses.reduce((acc, expense) => {
      const date = dayjs(expense.date).format("YYYY-MM-DD");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(expense);
      return acc;
    }, {});
    setGroupedExpenses(grouped);
  };

  const deleteExpenses = (id) => {
    if (window.confirm("هل أنت متأكد أنك تريد حذف هذا العنصر؟")) {
      axios
        .delete(`${apiUrl}/expenses/deleteExpense/${id}`)
        .then(() => {
          setExpenses((prevExpenses) =>
            prevExpenses.filter((e) => e._id !== id)
          );
          alert("تم حذف العنصر بنجاح");
          window.location.reload();
        })
        .catch((error) => {
          console.error("حدث خطأ أثناء حذف العنصر!", error);
          alert("فشل في الحذف. حاول مرة أخرى.");
        });
    }
  };

  const formatDate = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };

  const formatAmount = (amount) => {
    const parsedAmount = parseFloat(amount);
    return isNaN(parsedAmount)
      ? "0.00"
      : parsedAmount.toLocaleString("en-US", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        });
  };

  // Function to handle editing an item
  const handleEditItem = (debt) => {
    setEditExpenses({
      _id: debt._id,
      amount: debt.amount,
      description: debt.description,
      date: formatDate(debt.date),
    });
    setEditDialogOpen(true);
  };

  // Function to handle saving the edited payment
  const handleSaveEdit = () => {
    const { _id, amount, date, description } = editExpenses;

    axios
      .put(`${apiUrl}/expenses/updateExpense/${_id}`, {
        amount,
        date,
        description,
      })
      .then(() => {
        setExpenses((prevExpenses) =>
          prevExpenses.map((e) =>
            e._id === _id
              ? {
                  ...e,
                  amount,
                  date: dayjs(date).toISOString(),
                  description,
                }
              : e
          )
        );
        setEditDialogOpen(false);
        alert("تم التعديل بنجاح");
        window.location.reload();
      })
      .catch((error) => {
        console.error("حدث خطأ أثناء تعديل البيانات!", error);
        alert("فشل في التعديل. حاول مرة أخرى.");
      });
  };

  // Update the state when form inputs change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditExpenses((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Calculate the total amount for each day
  const calculateTotalAmountForDate = (date) => {
    const expensesForDate = groupedExpenses[date] || [];
    return expensesForDate.reduce(
      (total, expense) => total + parseFloat(expense.amount),
      0
    );
  };

  // Pagination handlers for Expenses Table
  const handleExpensesTableChangePage = (event, newPage) => {
    setExpensesTablePage(newPage);
  };

  const handleExpensesTableChangeRowsPerPage = (event) => {
    setExpensesTableRowsPerPage(parseInt(event.target.value, 10));
    setExpensesTablePage(0);
  };

  // Pagination handlers for Total Amount Table
  const handleTotalAmountTableChangePage = (event, newPage) => {
    setTotalAmountTablePage(newPage);
  };

  const handleTotalAmountTableChangeRowsPerPage = (event) => {
    setTotalAmountTableRowsPerPage(parseInt(event.target.value, 10));
    setTotalAmountTablePage(0);
  };

  const paginatedExpenses = expenses.slice(
    expensesTablePage * expensesTableRowsPerPage,
    expensesTablePage * expensesTableRowsPerPage + expensesTableRowsPerPage
  );

  const paginatedGroupedExpenses = Object.keys(groupedExpenses)
    .slice(
      totalAmountTablePage * totalAmountTableRowsPerPage,
      totalAmountTablePage * totalAmountTableRowsPerPage +
        totalAmountTableRowsPerPage
    )
    .map((date) => ({
      date,
      totalAmount: calculateTotalAmountForDate(date),
    }));

  return (
    <Container>
      <Typography
        variant="h5"
        gutterBottom
        fontWeight={"bold"}
        // marginRight={5}
        marginTop={3}
        marginBottom={1.5}
        backgroundColor={"white"}
        color={"black"}
        textAlign={"center"}
      >
        المصرف اليومي
      </Typography>
      {/* Expenses Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>المبلغ </TableCell>
              <TableCell>التاريخ </TableCell>
              <TableCell>ملاحظات </TableCell>
              <TableCell>{/*Actions*/}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedExpenses.length > 0 ? (
              paginatedExpenses.map((e) => (
                <TableRow key={e._id}>
                  <TableCell>{formatAmount(e.amount)}</TableCell>
                  <TableCell>{formatDate(e.date)}</TableCell>
                  <TableCell>{e.description}</TableCell>
                  <TableCell>
                    <IconButton
                      variant="contained"
                      color="primary"
                      sx={{
                        padding: "5px",
                        margin: "5px",
                        color: "#44484e",
                      }}
                      onClick={() => deleteExpenses(e._id)}
                    >
                      <ClearIcon />
                    </IconButton>

                    <IconButton
                      variant="contained"
                      color="primary"
                      sx={{
                        padding: "5px",
                        margin: "5px",
                        color: "#44484e",
                      }}
                      onClick={() => handleEditItem(e)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  لا توجد بيانات متاحة
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={expenses.length}
          rowsPerPage={expensesTableRowsPerPage}
          page={expensesTablePage}
          onPageChange={handleExpensesTableChangePage}
          onRowsPerPageChange={handleExpensesTableChangeRowsPerPage}
          sx={{ direction: "ltr" }}
        />
      </TableContainer>

      {/* Total Amount by Date Table */}
      <Typography
        variant="h6"
        gutterBottom
        fontWeight={"bold"}
        marginTop={3}
        marginBottom={1.5}
        backgroundColor={"white"}
        color={"black"}
        textAlign={"center"}
      >
        مجموع المصرف اليومي
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "center" }}>المجموع</TableCell>
              <TableCell sx={{ textAlign: "center" }}>التاريخ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedGroupedExpenses.length > 0 ? (
              paginatedGroupedExpenses.map((e) => (
                <TableRow key={e.date}>
                  <TableCell sx={{ textAlign: "center" }}>
                    {formatAmount(calculateTotalAmountForDate(e.date))}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    {formatDate(e.date)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  لا توجد بيانات متاحة
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={Object.keys(groupedExpenses).length}
          rowsPerPage={totalAmountTableRowsPerPage}
          page={totalAmountTablePage}
          onPageChange={handleTotalAmountTableChangePage}
          onRowsPerPageChange={handleTotalAmountTableChangeRowsPerPage}
          sx={{ direction: "ltr" }}
        />
      </TableContainer>

      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle
          sx={{ textAlign: "center", fontWeight: "bold", fontSize: "30px" }}
        >
          تعديل
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", margin: "5px" }}>
            <TextField
              label="المبلغ"
              name="amount"
              value={editExpenses.amount}
              onChange={handleInputChange}
              fullWidth
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#44484e",
                  },
                  "&:hover fieldset": {
                    borderColor: "#44484e",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#44484e",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#44484e",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#44484e",
                },
              }}
            />
            <TextField
              label="التاريخ"
              type="date"
              name="date"
              value={editExpenses.date}
              onChange={handleInputChange}
              fullWidth
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#44484e",
                  },
                  "&:hover fieldset": {
                    borderColor: "#44484e",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#44484e",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#44484e",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#44484e",
                },
              }}
            />
            <TextField
              label="الوصف"
              name="description"
              value={editExpenses.description}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={4}
              sx={{
                marginBottom: "20px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#44484e",
                  },
                  "&:hover fieldset": {
                    borderColor: "#44484e",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#44484e",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#44484e",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#44484e",
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setEditDialogOpen(false)}
            sx={{ color: "#44484e" }}
          >
            إغلاق
          </Button>
          <Button onClick={handleSaveEdit} sx={{ color: "#44484e" }}>
            حفظ التعديلات
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ExpensesTable; 

// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Container,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   IconButton,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TablePagination,
//   TableRow,
//   TextField,
//   Typography,
// } from "@mui/material";
// import ClearIcon from "@mui/icons-material/Clear";
// import EditIcon from "@mui/icons-material/Edit";
// import axios from "axios";
// import dayjs from "dayjs";

// const apiUrl = process.env.REACT_APP_SERVER_URL;

// const ExpensesTable = () => {
//   const [expenses, setExpenses] = useState([]);
//   const [groupedExpenses, setGroupedExpenses] = useState({});
//   const [editDialogOpen, setEditDialogOpen] = useState(false);
//   const [editExpenses, setEditExpenses] = useState({
//     _id: "",
//     amount: "",
//     description: "",
//     date: "",
//   });

//   // Separate pagination for the two tables
//   const [expensesTablePage, setExpensesTablePage] = useState(0);
//   const [expensesTableRowsPerPage, setExpensesTableRowsPerPage] = useState(5);

//   const [totalAmountTablePage, setTotalAmountTablePage] = useState(0);
//   const [totalAmountTableRowsPerPage, setTotalAmountTableRowsPerPage] =
//     useState(5);

//   useEffect(() => {
//     fetchExpenses();
//   }, []);

//   const fetchExpenses = () => {
//     axios
//       .get(`${apiUrl}/expenses/getExpense`)
//       .then((response) => {
//         const data = response.data || [];
//         setExpenses(data);
//         groupExpensesByDate(data);
//       })
//       .catch((error) => {
//         console.error(
//           "There was an error fetching the expenses details!",
//           error
//         );
//       });
//   };

//   const groupExpensesByDate = (expenses) => {
//     const grouped = expenses.reduce((acc, expense) => {
//       const date = dayjs(expense.date).format("YYYY-MM-DD");
//       if (!acc[date]) {
//         acc[date] = [];
//       }
//       acc[date].push(expense);
//       return acc;
//     }, {});
//     setGroupedExpenses(grouped);
//   };

//   const deleteExpenses = (id) => {
//     if (window.confirm("هل أنت متأكد أنك تريد حذف هذا العنصر؟")) {
//       axios
//         .delete(`${apiUrl}/expenses/deleteExpense/${id}`)
//         .then(() => {
//           setExpenses((prevExpenses) =>
//             prevExpenses.filter((e) => e._id !== id)
//           );
//           alert("تم حذف العنصر بنجاح");
//           window.location.reload();
//         })
//         .catch((error) => {
//           console.error("حدث خطأ أثناء حذف العنصر!", error);
//           alert("فشل في الحذف. حاول مرة أخرى.");
//         });
//     }
//   };

//   const formatDate = (date) => {
//     return dayjs(date).format("YYYY-MM-DD");
//   };

//   const formatAmount = (amount) => {
//     const parsedAmount = parseFloat(amount);
//     return isNaN(parsedAmount)
//       ? "0.00"
//       : parsedAmount.toLocaleString("en-US", {
//           minimumFractionDigits: 3,
//           maximumFractionDigits: 3,
//         });
//   };

//   // Function to handle editing an item
//   const handleEditItem = (debt) => {
//     setEditExpenses({
//       _id: debt._id,
//       amount: debt.amount,
//       description: debt.description,
//       date: formatDate(debt.date),
//     });
//     setEditDialogOpen(true);
//   };

//   // Function to handle saving the edited payment
//   const handleSaveEdit = () => {
//     const { _id, amount, date, description } = editExpenses;

//     axios
//       .put(`${apiUrl}/expenses/updateExpense/${_id}`, {
//         amount,
//         date,
//         description,
//       })
//       .then(() => {
//         setExpenses((prevExpenses) =>
//           prevExpenses.map((e) =>
//             e._id === _id
//               ? {
//                   ...e,
//                   amount,
//                   date: dayjs(date).toISOString(),
//                   description,
//                 }
//               : e
//           )
//         );
//         setEditDialogOpen(false);
//         alert("تم التعديل بنجاح");
//         window.location.reload();
//       })
//       .catch((error) => {
//         console.error("حدث خطأ أثناء تعديل البيانات!", error);
//         alert("فشل في التعديل. حاول مرة أخرى.");
//       });
//   };

//   // Update the state when form inputs change
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setEditExpenses((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   // Calculate the total amount for each day
//   const calculateTotalAmountForDate = (date) => {
//     const expensesForDate = groupedExpenses[date] || [];
//     return expensesForDate.reduce(
//       (total, expense) => total + parseFloat(expense.amount),
//       0
//     );
//   };

//   // Pagination handlers for Expenses Table
//   const handleExpensesTableChangePage = (event, newPage) => {
//     setExpensesTablePage(newPage);
//   };

//   const handleExpensesTableChangeRowsPerPage = (event) => {
//     setExpensesTableRowsPerPage(parseInt(event.target.value, 10));
//     setExpensesTablePage(0);
//   };

//   // Pagination handlers for Total Amount Table
//   const handleTotalAmountTableChangePage = (event, newPage) => {
//     setTotalAmountTablePage(newPage);
//   };

//   const handleTotalAmountTableChangeRowsPerPage = (event) => {
//     setTotalAmountTableRowsPerPage(parseInt(event.target.value, 10));
//     setTotalAmountTablePage(0);
//   };

//   const paginatedExpenses = expenses.slice(
//     expensesTablePage * expensesTableRowsPerPage,
//     expensesTablePage * expensesTableRowsPerPage + expensesTableRowsPerPage
//   );

//   const paginatedGroupedExpenses = Object.keys(groupedExpenses)
//     .slice(
//       totalAmountTablePage * totalAmountTableRowsPerPage,
//       totalAmountTablePage * totalAmountTableRowsPerPage +
//         totalAmountTableRowsPerPage
//     )
//     .map((date) => ({
//       date,
//       totalAmount: calculateTotalAmountForDate(date),
//     }));

//   return (
//     <Container>
//       <Typography
//         variant="h5"
//         gutterBottom
//         fontWeight={"bold"}
//         marginTop={3}
//         marginBottom={1.5}
//         backgroundColor={"white"}
//         color={"black"}
//         textAlign={"center"}
//       >
//         المصرف اليومي
//       </Typography>
//       {/* Expenses Table */}
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>المبلغ </TableCell>
//               <TableCell>التاريخ </TableCell>
//               <TableCell>ملاحظات </TableCell>
//               <TableCell>{/*Actions*/}</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {paginatedExpenses.length > 0 ? (
//               paginatedExpenses.map((e) => (
//                 <TableRow key={e._id}>
//                   <TableCell>{formatAmount(e.amount)}</TableCell>
//                   <TableCell>{formatDate(e.date)}</TableCell>
//                   <TableCell>{e.description}</TableCell>
//                   <TableCell>
//                     <IconButton
//                       variant="contained"
//                       color="primary"
//                       sx={{
//                         padding: "5px",
//                         margin: "5px",
//                         color: "#44484e",
//                       }}
//                       onClick={() => deleteExpenses(e._id)}
//                     >
//                       <ClearIcon />
//                     </IconButton>

//                     <IconButton
//                       variant="contained"
//                       color="primary"
//                       sx={{
//                         padding: "5px",
//                         margin: "5px",
//                         color: "#44484e",
//                       }}
//                       onClick={() => handleEditItem(e)}
//                     >
//                       <EditIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={5} align="center">
//                   لا توجد بيانات متاحة
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//         <TablePagination
//           rowsPerPageOptions={[5, 10, 25]}
//           component="div"
//           count={expenses.length}
//           rowsPerPage={expensesTableRowsPerPage}
//           page={expensesTablePage}
//           onPageChange={handleExpensesTableChangePage}
//           onRowsPerPageChange={handleExpensesTableChangeRowsPerPage}
//           sx={{ direction: "ltr" }}
//         />
//       </TableContainer>

//       {/* Total Amount by Date Table */}
//       <Typography
//         variant="h6"
//         gutterBottom
//         fontWeight={"bold"}
//         marginTop={3}
//         marginBottom={1.5}
//         backgroundColor={"white"}
//         color={"black"}
//         textAlign={"center"}
//       >
//         إجمالي المصاريف حسب التاريخ
//       </Typography>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>التاريخ</TableCell>
//               <TableCell>الإجمالي</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {paginatedGroupedExpenses.length > 0 ? (
//               paginatedGroupedExpenses.map((e) => (
//                 <TableRow key={e.date}>
//                   <TableCell>{e.date}</TableCell>
//                   <TableCell>{formatAmount(e.totalAmount)}</TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={2} align="center">
//                   لا توجد بيانات متاحة
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//         <TablePagination
//           rowsPerPageOptions={[5, 10, 25]}
//           component="div"
//           count={Object.keys(groupedExpenses).length}
//           rowsPerPage={totalAmountTableRowsPerPage}
//           page={totalAmountTablePage}
//           onPageChange={handleTotalAmountTableChangePage}
//           onRowsPerPageChange={handleTotalAmountTableChangeRowsPerPage}
//           sx={{ direction: "ltr" }}
//         />
//       </TableContainer>

//       {/* Edit Dialog */}
//       <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
//         <DialogTitle>تعديل المصاريف</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="المبلغ"
//             variant="outlined"
//             name="amount"
//             value={editExpenses.amount}
//             onChange={handleInputChange}
//             fullWidth
//             margin="normal"
//             type="number"
//           />
//           <TextField
//             label="الملاحظات"
//             variant="outlined"
//             name="description"
//             value={editExpenses.description}
//             onChange={handleInputChange}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="التاريخ"
//             variant="outlined"
//             name="date"
//             value={editExpenses.date}
//             onChange={handleInputChange}
//             fullWidth
//             margin="normal"
//             type="date"
//             InputLabelProps={{
//               shrink: true,
//             }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setEditDialogOpen(false)} color="primary">
//             إلغاء
//           </Button>
//           <Button onClick={handleSaveEdit} color="primary" variant="contained">
//             حفظ
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// };

// export default ExpensesTable;
