import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/image/logoWhite.png";
import logo2 from "../../assets/image/logoBlack.png";
import { useAuth } from "../../context/auth";
import toast from "react-hot-toast";

const drawerWidth = 200;

const Header = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [auth, setAuth] = useAuth(); 

  const handleLogout = (event) => {
    event.preventDefault();
    try {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
      localStorage.removeItem("auth");
      toast.success("Logout Successfully");
      navigate("/login");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // dropdown menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  // for small screen
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ margin: "20px" }}>
        <img
          src={logo2}
          alt="Logo"
          style={{ width: "100px", height: "auto" }}
          onClick={() => navigate("/")}
        />
      </Box>

      <Divider />
      <List>
        {!auth?.user ? (
          <>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={() => navigate("/register")}
                  >
                    حساب جديد {/* Register */}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={() => navigate("/login")}
                  >
                    تسجيل دخول {/* Login */}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={() => navigate("/")}
                  >
                    الرئيسية {/* Home */}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={() => navigate("/customer")}
                  >
                    الزبائن {/* Customer */}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={() => navigate("/product")}
                  >
                    المنتجات Products
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={() => navigate("/category")}
                  >
                    الفئات {/* Categories */}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={() => navigate("/charts")} // /charts
                  >
                    الجرد {/* Home */}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={() => navigate("/expenses")} // /charts
                  >
                    المصرف اليومي
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={() => navigate("/debtPayment")}
                  >
                    الديون
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "20px", fontWeight: "bold" }}
                    onClick={handleLogout}
                  >
                    تسجيل الخروج {/* Logout */}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* for big screen */}
      <AppBar component="nav" sx={{ background: "#44484e", maxHeight: "64px" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
            <img
              src={logo}
              alt="Logo"
              style={{ width: "100px", height: "auto" }}
              onClick={() => navigate("/")}
            />
          </Box>
          {/* <SearchForm /> */}
          <Box sx={{ display: { xs: "none", sm: "block" }, maxHeight: "64px" }}>
            {!auth?.user ? (
              <>
                <Button
                  sx={{ color: "#fff", fontSize: "23px", fontWeight: "bold" }}
                  onClick={() => navigate("/register")}
                >
                  حساب جديد {/* Register */}
                </Button>
                <Button
                  sx={{ color: "#fff", fontSize: "23px", fontWeight: "bold" }}
                  onClick={() => navigate("/login")}
                >
                  تسجيل الدخول {/* Login */}
                </Button>
              </>
            ) : (
              <>
                <Button
                  sx={{ color: "#fff", fontSize: "23px", fontWeight: "bold" }}
                  onClick={() => navigate("/product")}
                >
                  المنتجات {/*  Products */}
                </Button>

                <Button
                  sx={{ color: "#fff", fontSize: "23px", fontWeight: "bold" }}
                  onClick={() => navigate("/customer")}
                >
                  الزبائن {/* Customer */}
                </Button>

                <Button
                  sx={{ color: "#fff", fontSize: "23px", fontWeight: "bold" }}
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  {auth?.user?.name}
                </Button>
                <Menu
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: "#44484d",
                    },
                  }}
                >
                  <MenuItem onClick={handleClose}>
                    <Button
                      sx={{
                        color: "#fff",
                        fontSize: "23px",
                        fontWeight: "bold",
                      }}
                      fullWidth
                      onClick={() => navigate("/category")}
                    >
                      الفئات {/* Categories */}
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      sx={{
                        color: "#fff",
                        fontSize: "23px",
                        fontWeight: "bold",
                      }}
                      fullWidth
                      onClick={() => navigate("/charts")} // /charts
                    >
                      الجرد {/* Home */}
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      sx={{
                        color: "#fff",
                        fontSize: "23px",
                        fontWeight: "bold",
                      }}
                      fullWidth
                      onClick={() => navigate("/expenses")} // /charts
                    >
                      المصرف اليومي
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      sx={{
                        color: "#fff",
                        fontSize: "23px",
                        fontWeight: "bold",
                      }}
                      fullWidth
                      onClick={() => navigate("/debtPayment")} // /charts
                    >
                      الديون
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      sx={{
                        color: "#fff",
                        fontSize: "23px",
                        fontWeight: "bold",
                      }}
                      fullWidth
                      onClick={handleLogout}
                    >
                      تسجيل الخروج {/* Logout */}
                    </Button>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        {/* for small screen  */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
