// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from "recharts";
// import toast from "react-hot-toast";
// import { getISOWeek } from "date-fns";
// import {
//   Typography,
//   Box,
//   Grid,
//   Container,
//   Card,
//   CardContent,
// } from "@mui/material";
// import PropTypes from "prop-types";

// const apiUrl = process.env.REACT_APP_SERVER_URL;

// const CustomYAxis = ({ tickFormatter = (tick) => tick, ...props }) => (
//   <YAxis tickFormatter={tickFormatter} {...props} />
// );

// CustomYAxis.propTypes = {
//   tickFormatter: PropTypes.func,
// };

// const CustomXAxis = ({ dataKey = "", ...props }) => (
//   <XAxis dataKey={dataKey} {...props} />
// );

// CustomXAxis.propTypes = {
//   dataKey: PropTypes.string,
// };

// const CustomerChart = () => {
//   const [customers, setCustomers] = useState([]);
//   const [products, setProducts] = useState([]);

//   const getAllCustomers = async () => {
//     try {
//       const { data } = await axios.get(`${apiUrl}/customer/getCustomer`);
//       setCustomers(data.customers);
//     } catch (error) {
//       console.log(error);
//       toast.error("Something went wrong while fetching customers.");
//     }
//   };

//   useEffect(() => {
//     getAllCustomers();
//   }, []);

//   const getAllProducts = async () => {
//     try {
//       const { data } = await axios.get(`${apiUrl}/product/getProduct`);
//       setProducts(data.products);
//     } catch (error) {
//       console.log(error);
//       toast.error("Something Went Wrong");
//     }
//   };

//   useEffect(() => {
//     getAllProducts();
//   }, []);

//   const formatAmount = (amount) => {
//     const parsedAmount = parseFloat(amount);
//     return isNaN(parsedAmount)
//       ? "0.000"
//       : parsedAmount.toLocaleString("en-US", {
//           minimumFractionDigits: 3,
//           maximumFractionDigits: 3,
//         });
//   };

//   const getYearlyData = () => {
//     const yearlyData = customers.reduce((acc, curr) => {
//       const year = new Date(curr.date).getFullYear();
//       acc[year] = (acc[year] || 0) + curr.price;
//       return acc;
//     }, {});
//     return Object.keys(yearlyData).map((year) => ({
//       year,
//       price: yearlyData[year],
//       formattedPrice: formatAmount(yearlyData[year]),
//     }));
//   };

//   const getMonthlyData = () => {
//     const monthlyData = customers.reduce((acc, curr) => {
//       const monthYear = `${new Date(curr.date).getFullYear()}-${
//         new Date(curr.date).getMonth() + 1
//       }`;
//       acc[monthYear] = (acc[monthYear] || 0) + curr.price;
//       return acc;
//     }, {});
//     return Object.keys(monthlyData).map((monthYear) => ({
//       monthYear,
//       price: monthlyData[monthYear],
//       formattedPrice: formatAmount(monthlyData[monthYear]),
//     }));
//   };

//   const getWeeklyData = () => {
//     const weeklyData = customers.reduce((acc, curr) => {
//       const weekYear = `${new Date(curr.date).getFullYear()}-W${getISOWeek(
//         new Date(curr.date)
//       )}`;
//       acc[weekYear] = (acc[weekYear] || 0) + curr.price;
//       return acc;
//     }, {});
//     return Object.keys(weeklyData).map((weekYear) => ({
//       weekYear,
//       price: weeklyData[weekYear],
//       formattedPrice: formatAmount(weeklyData[weekYear]),
//     }));
//   };

//   const sumOfPrices = () => {
//     return customers.reduce((acc, curr) => acc + curr.price, 0);
//   };

//   const sumOfCustomers = () => {
//     return products.length;
//   };

//   const sumOfDiscounts = () => {
//     return customers.reduce((acc, curr) => {
//       if (!isNaN(curr.discount)) {
//         return acc + curr.discount;
//       } else {
//         return acc;
//       }
//     }, 0);
//   };

//   const sumOfExpense = () => {
//     return products.reduce((acc, curr) => acc + curr.price, 0);
//   };

//   const sumOfProfit = () => {
//     return sumOfPrices() - sumOfExpense();
//   };

//   return (
//     <Container
//       style={{ marginRight: "150px", marginTop: "20px", marginLeft: "auto" }}
//     >
//       <Grid container spacing={2} justifyContent="center">
//         <Grid
//           container
//           spacing={{ xs: 3, md: 2 }}
//           columns={{ xs: 4, sm: 8, md: 14 }}
//           justifyContent="center"
//           sx={{ maxWidth: "90%", marginBottom: "20px" }}
//         >
//           <Grid item xs={2} sm={4} md={4}>
//             <Card>
//               <CardContent>
//                 <Typography variant="h4" align="center" gutterBottom>
//                   مجموع الزبائن
//                 </Typography>
//                 <Typography variant="h5" align="center">
//                   {sumOfCustomers()}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>

//           <Grid item xs={2} sm={4} md={4}>
//             <Card>
//               <CardContent>
//                 <Typography variant="h4" align="center" gutterBottom>
//                   مجموع الخصم
//                 </Typography>
//                 <Typography variant="h5" align="center">
//                   {`${sumOfDiscounts().toLocaleString("en-IQ", {
//                     style: "currency",
//                     currency: "IQD",
//                   })},000`}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>

//           <Grid item xs={2} sm={4} md={4}>
//             <Card>
//               <CardContent>
//                 <Typography variant="h4" align="center" gutterBottom>
//                   مجموع الدخل
//                 </Typography>
//                 <Typography variant="h5" align="center">
//                   {`${sumOfPrices().toLocaleString("en-IQ", {
//                     style: "currency",
//                     currency: "IQD",
//                   })},000`}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>

//           <Grid item xs={2} sm={4} md={4}>
//             <Card>
//               <CardContent>
//                 <Typography variant="h4" align="center" gutterBottom>
//                   مجموع المصروف
//                 </Typography>
//                 <Typography variant="h5" align="center">
//                   {`${sumOfExpense().toLocaleString("en-IQ", {
//                     style: "currency",
//                     currency: "IQD",
//                   })},000`}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>

//           <Grid item xs={2} sm={4} md={4}>
//             <Card>
//               <CardContent>
//                 <Typography variant="h4" align="center" gutterBottom>
//                   مجموع الربح
//                 </Typography>
//                 <Typography variant="h5" align="center">
//                   {`${sumOfProfit().toLocaleString("en-IQ", {
//                     style: "currency",
//                     currency: "IQD",
//                   })},000`}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         </Grid>

//         <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
//           <Grid item xs={6}>
//             <Box
//               border={1}
//               borderRadius={4}
//               p={2}
//               width={600}
//               bgcolor="#f0f0f0"
//             >
//               <Typography variant="h5" align="center" gutterBottom>
//                 الجرد السنوي
//               </Typography>
//               <BarChart
//                 width={500}
//                 height={250}
//                 data={getYearlyData()}
//                 style={{ marginLeft: "50px" }}
//                 margin={{ top: 10, right: 30, left: 30, bottom: 5 }}
//               >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <CustomXAxis dataKey="year" />
//                 <CustomYAxis tickFormatter={(tick) => formatAmount(tick)} />
//                 <Tooltip formatter={(value) => formatAmount(value)} />
//                 <Legend />
//                 <Bar dataKey="price" fill="#586F7C" />
//               </BarChart>
//             </Box>
//           </Grid>

//           <Grid item xs={6}>
//             <Box
//               border={1}
//               borderRadius={4}
//               p={2}
//               width={600}
//               bgcolor="#f0f0f0"
//             >
//               <Typography variant="h5" align="center" gutterBottom>
//                 الجرد الشهري
//               </Typography>
//               <BarChart
//                 width={500}
//                 height={250}
//                 data={getMonthlyData()}
//                 style={{ marginLeft: "50px" }}
//                 margin={{ top: 10, right: 30, left: 30, bottom: 5 }}
//               >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <CustomXAxis dataKey="monthYear" />
//                 <CustomYAxis tickFormatter={(tick) => formatAmount(tick)} />
//                 <Tooltip formatter={(value) => formatAmount(value)} />
//                 <Legend />
//                 <Bar dataKey="price" fill="#586F7C" />
//               </BarChart>
//             </Box>
//           </Grid>

//           <Grid item xs={6}>
//             <Box
//               border={1}
//               borderRadius={4}
//               p={2}
//               width={600}
//               bgcolor="#f0f0f0"
//             >
//               <Typography variant="h5" align="center" gutterBottom>
//                 الجرد الاسبوعي
//               </Typography>
//               <BarChart
//                 width={500}
//                 height={250}
//                 data={getWeeklyData()}
//                 style={{ marginLeft: "50px" }}
//                 margin={{ top: 10, right: 30, left: 30, bottom: 5 }}
//               >
//                 <CartesianGrid strokeDasharray="3 3" />
//                 <CustomXAxis dataKey="weekYear" />
//                 <CustomYAxis tickFormatter={(tick) => formatAmount(tick)} />
//                 <Tooltip formatter={(value) => formatAmount(value)} />
//                 <Legend />
//                 <Bar dataKey="price" fill="#586F7C" />
//               </BarChart>
//             </Box>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };

// export default CustomerChart;

import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {
  Typography,
  Grid,
  Container,
  Card,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
} from "@mui/material";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);

const apiUrl = process.env.REACT_APP_SERVER_URL;

const CustomerChart = () => {
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [totals, setTotals] = useState({ week: 0, month: 0, year: 0 });

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    getAllCustomers();
    getAllProducts();
    fetchExpenses();
  }, []);

  const getAllCustomers = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/customer/getCustomer`);
      setCustomers(data.customers);
    } catch (error) {
      console.error(error);
      toast.error("Error fetching customers.");
    }
  };

  const getAllProducts = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/product/getProduct`);
      setProducts(data.products);
    } catch (error) {
      console.error(error);
      toast.error("Error fetching products.");
    }
  };

  const fetchExpenses = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/expenses/getExpense`);
      setExpenses(data || []);
    } catch (error) {
      console.error("Error fetching expenses:", error);
    }
  };

  const sumOfPrices = () => {
    return customers.reduce((acc, curr) => acc + curr.price, 0);
  };

  const sumOfCustomers = () => {
    return products.length;
  };

  const sumOfDiscounts = () => {
    return customers.reduce((acc, curr) => {
      if (!isNaN(curr.discount)) {
        return acc + curr.discount;
      } else {
        return acc;
      }
    }, 0);
  };

  const sumOfExpense = () => {
    return products.reduce((acc, curr) => acc + curr.price, 0);
  };

  const sumOfDailyExpense = () => {
    return expenses.reduce((acc, curr) => acc + curr.amount, 0);
  };

  const sumOfProfit = () => {
    return sumOfPrices() - (sumOfExpense() + sumOfDailyExpense());
    // return sumOfPrices() - sumOfExpense();
  };


  useEffect(() => {
    if (customers.length && products.length && expenses.length) {
      const groupedData = {};

      customers.forEach(({ date, price }) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        groupedData[formattedDate] = groupedData[formattedDate] || {
          income: 0,
          productExpense: 0,
          personalExpense: 0,
        };
        groupedData[formattedDate].income += price;
      });

      products.forEach(({ date, price }) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        if (!groupedData[formattedDate]) {
          groupedData[formattedDate] = {
            income: 0,
            productExpense: 0,
            personalExpense: 0,
          };
        }
        groupedData[formattedDate].productExpense += parseFloat(price) || 0;
      });

      expenses.forEach(({ date, amount }) => {
        const formattedDate = dayjs(date).format("YYYY-MM-DD");
        groupedData[formattedDate] = groupedData[formattedDate] || {
          income: 0,
          productExpense: 0,
          personalExpense: 0,
        };
        groupedData[formattedDate].personalExpense += amount;
      });

      const dailyTotals = Object.entries(groupedData).map(([date, values]) => ({
        date,
        income: values.income,
        expenses: values.productExpense + values.personalExpense,
        reset: values.income - (values.productExpense + values.personalExpense),
      }));

      setDailyData(dailyTotals);

      // Calculate weekly, monthly, and yearly totals
      const today = dayjs();
      const weeklyTotal = dailyTotals
        .filter(({ date }) => dayjs(date).isoWeek() === today.isoWeek())
        .reduce((sum, { reset }) => sum + reset, 0);

      const monthlyTotal = dailyTotals
        .filter(({ date }) => dayjs(date).month() === today.month())
        .reduce((sum, { reset }) => sum + reset, 0);

      const yearlyTotal = dailyTotals
        .filter(({ date }) => dayjs(date).year() === today.year())
        .reduce((sum, { reset }) => sum + reset, 0);

      setTotals({
        week: weeklyTotal,
        month: monthlyTotal,
        year: yearlyTotal,
      });
    }
  }, [customers, products, expenses]);

  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Slice data for pagination
  const paginatedData = dailyData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Container style={{ marginTop: "20px", direction: "rtl" }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid
          container
          spacing={{ xs: 3, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 14 }}
          justifyContent="center"
          sx={{ maxWidth: "90%", marginBottom: "20px" }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" align="center" gutterBottom>
                  مصرف البضاعة
                </Typography>
                <Typography variant="h5" align="center">
                  {`${sumOfExpense().toLocaleString("en-IQ", {
                    style: "currency",
                    currency: "IQD",
                  })},000`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" align="center" gutterBottom>
                  المصرف الشخصي
                </Typography>
                <Typography variant="h5" align="center">
                  {`${sumOfDailyExpense().toLocaleString("en-IQ", {
                    style: "currency",
                    currency: "IQD",
                  })},000`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" align="center" gutterBottom>
                  مجموع الربح
                </Typography>
                <Typography variant="h5" align="center">
                  {`${sumOfProfit().toLocaleString("en-IQ", {
                    style: "currency",
                    currency: "IQD",
                  })},000`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" align="center" gutterBottom>
                  مجموع الدخل
                </Typography>
                <Typography variant="h5" align="center">
                  {`${sumOfPrices().toLocaleString("en-IQ", {
                    style: "currency",
                    currency: "IQD",
                  })},000`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" align="center" gutterBottom>
                  عدد الزبائن
                </Typography>
                <Typography variant="h5" align="center">
                  {sumOfCustomers()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" align="center" gutterBottom>
                  مجموع الخصم
                </Typography>
                <Typography variant="h5" align="center">
                  {`${sumOfDiscounts().toLocaleString("en-IQ", {
                    style: "currency",
                    currency: "IQD",
                  })},000`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      {/* table of daily and monthly */}
      <Box marginBottom={4}>
        <Typography
          variant="h6"
          gutterBottom
          fontWeight={"bold"}
          marginTop={3}
          marginBottom={1.5}
          backgroundColor={"white"}
          color={"black"}
          textAlign={"center"}
        >
          الربح اليومي
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>
                  الدخل {/*(Income)*/}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  المصروفات {/*(Expenses)*/}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  الربح الصافي {/*(Reset)*/}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  التاريخ {/*(Date)*/}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.length ? (
                paginatedData.map(({ date, income, expenses, reset }) => (
                  <TableRow key={date}>
                    <TableCell sx={{ textAlign: "center" }}>
                      {income.toLocaleString("en-IQ", {
                        style: "currency",
                        currency: "IQD",
                      })}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {expenses.toLocaleString("en-IQ", {
                        style: "currency",
                        currency: "IQD",
                      })}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {reset.toLocaleString("en-IQ", {
                        style: "currency",
                        currency: "IQD",
                      })}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>{date}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    لا توجد بيانات متاحة
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={dailyData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ direction: "ltr" }}
          />
        </TableContainer>
      </Box>

      <Grid container spacing={2} justifyContent="center">
        <Grid
          container
          spacing={{ xs: 3, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 14 }}
          justifyContent="center"
          sx={{ maxWidth: "90%", margin: "40px" }}
        >
          <Grid item xs={2} sm={4} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h4" align="center" gutterBottom>
                  الربح الأسبوعي
                </Typography>
                <Typography variant="h5" align="center">
                  {totals.week.toLocaleString("en-IQ", {
                    style: "currency",
                    currency: "IQD",
                  })}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h4" align="center" gutterBottom>
                  الربح الشهري
                </Typography>
                <Typography variant="h5" align="center">
                  {totals.month.toLocaleString("en-IQ", {
                    style: "currency",
                    currency: "IQD",
                  })}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={2} sm={4} md={4}>
            <Card>
              <CardContent>
                <Typography variant="h4" align="center" gutterBottom>
                  الربح السنوي
                </Typography>
                <Typography variant="h5" align="center">
                  {totals.year.toLocaleString("en-IQ", {
                    style: "currency",
                    currency: "IQD",
                  })}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomerChart;
